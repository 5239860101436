import React from "react";
import { Container, Title, Image, Description, Iframe } from "./styles";
import Placeholder from "../../assets/images/placeholder.jpg";

function ReadMore({ content }) {
  return (
    <Container>
      <Title dangerouslySetInnerHTML={{ __html: content.title }} />
      <Image
        src={(content.file && content.file.urlPublica) || Placeholder}
        alt='banner texto'
      />
      <Description dangerouslySetInnerHTML={{ __html: content.description }} />
      <Iframe
        src='https://www.youtube.com/embed/geb6Up905JE'
        frameborder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowfullscreen
        title='ReadMore'
      />
      <Description>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
        varius enim in eros elementum tristique. Duis cursus, mi quis viverra
        ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.
        Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut
        sem vitae risus tristique posuere.Lorem ipsum dolor sit amet,
        consectetur adipiscing elit. Suspendisse varius enim in eros elementum
        tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum
        nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo
        cursus id rutrum lorem imperdiet.
      </Description>
    </Container>
  );
}

export default ReadMore;
