/** @format */

import styled from "styled-components";
import { GlobalContainer } from "../../../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  margin: 25px auto 60px;
  width: 100%;
  min-height: 50px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0px;
  border-style: none;
  border-top-color: rgba(51, 51, 51, 0.25);
  border-bottom-color: rgba(51, 51, 51, 0.25);
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 12%);
  padding-bottom: 15px;
  padding-top: 15px;
`;

export const Title = styled.h4`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 auto;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  cursor: pointer;
  color: var(--secondary-color);
  &.active {
    color: #002cf4;
  }
`;
export const TitleQtd = styled.span`
  display: flex;
  align-items: center;
  margin-left: 2px;
  justify-content: flex-start;
  flex: 0 auto;
  font-size: 18px;
  font-weight: 100;
  line-height: 30px;
  cursor: pointer;
`;

export const Description = styled.p``;

export const Icon = styled.i`
  padding: 0 10px;
  font-size: 13px;
  transform: rotate(${({ isVisible }) => (isVisible ? "-180deg" : "0deg")});
  transition: all 0.4s ease;
`;

export const Courses = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
