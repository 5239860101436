import React from "react";
import { PdfContainer, PdfText } from "./styles";
import { updateWatcheds } from "../../../services/endpoints/general/watcheds";
import { useCourseStatusContext } from "../../../contexts/CourseStatusProvider";

const FileZIP = ({ content, contentIri, userIri }) => {
  const { updateCourseStatus } = useCourseStatusContext();

  const handleClick = async (event) => {
    event.preventDefault();
    try {
      const url = content.file.urlPublica;
      if (window.open(url, "_blank")) {
        const body = {
          user_iri: userIri,
          progress: 0,
          content_iri: contentIri,
          watched: false,
        };
        await updateWatcheds(body);

        Object.assign(body, { progress: 100, watched: true });
        await updateWatcheds(body);
        updateCourseStatus();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className='embed-responsive embed-responsive-16by9'>
      <PdfContainer className='embed-responsive-item'>
        <PdfText>
          <strong onClick={(e) => handleClick(e)}>Clique aqui</strong>
          {" realizar download"}
        </PdfText>
      </PdfContainer>
    </div>
  );
};

export default FileZIP;
