import React from "react";
import PropTypes from "prop-types";
import * as Components from "./Components";

const ComponentFactory = ({ componentName, content, title, page }) => {
  const ComponentToRender = Components[componentName] || (() => <></>);
  return <ComponentToRender content={content} title={title} page={page} />;
};

ComponentFactory.propTypes = {
  componentName: PropTypes.string.isRequired,
};

export default ComponentFactory;
