import React, { useState } from "react";
import {
  updateCommentsById,
  deleteCommentsById,
} from "../../../../../services/endpoints/general/comments";
import {
  Container,
  Header,
  Title,
  Button,
  Description,
  TitleEdit,
  DescriptionEdit,
  EditOptions,
  ButtonEdit,
} from "./styles";
import { stripTags } from "../../../../../utils/manipulateString";
import { Spinner } from "../../../../Components";

const Note = ({ note, userId, lessonId, setReload }) => {
  const [isVisible, setIsvisible] = useState(false);
  const [title, setTitle] = useState((note && note.title) || "");
  const [description, setDescription] = useState(
    (note && note.description) || ""
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsEditingLoading(true);

    try {
      const body = {
        user_iri: `/users/${userId}`,
        parent_iri: `/lms/lessons/${lessonId}`,
        title,
        description,
      };

      await updateCommentsById(note.id, body);

      setReload(new Date().toLocaleString());
      setIsEditing(false);
    } catch (err) {
      console.error(err);
      alert("Ocorreu um erro ao editar a anotação.");
    }

    setIsEditingLoading(false);
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    try {
      const hasConfirmed = window.confirm(
        "Tem certeza que deseja deletar a anotação?"
      );

      if (hasConfirmed) {
        setIsDeletingLoading(true);
        await deleteCommentsById(note.id);
      }

      setReload(new Date().toLocaleString());
    } catch (err) {
      console.error(err);
      alert("Ocorreu um erro ao delete a anotação.");
    }

    setIsDeletingLoading(false);
  };

  const handleEditCancel = (e) => {
    e.preventDefault();
    setTitle(note.title);
    setDescription(note.description);
    setIsEditing(false);
  };

  if (!note) return null;
  return (
    <Container>
      <Header>
        {!isEditing ? (
          <Title
            isVisible={isVisible}
            dangerouslySetInnerHTML={{ __html: note.title }}
            onClick={(e) => setIsvisible((prev) => !prev)}
          />
        ) : (
          <TitleEdit
            defaultValue={title}
            type='text'
            required
            onChange={(e) => setTitle(e.target.value)}
          />
        )}

        <Button onClick={(e) => setIsEditing(true)}>Editar</Button>
        <Button onClick={(e) => handleDelete(e)}>Excluir</Button>
      </Header>
      {!isEditing ? (
        <Description
          isVisible={isVisible}
          dangerouslySetInnerHTML={{ __html: stripTags(note.description) }}
        />
      ) : (
        <DescriptionEdit
          defaultValue={description}
          type='text'
          required
          onChange={(e) => setDescription(e.target.value)}
        />
      )}
      {isEditing && (
        <EditOptions>
          <ButtonEdit onClick={(e) => handleEditCancel(e)}>
            {isDeletingLoading ? <Spinner size='sm' /> : "Cancelar"}
          </ButtonEdit>

          <ButtonEdit onClick={(e) => handleUpdate(e)}>
            {isEditingLoading ? <Spinner size='sm' /> : "Salvar"}
          </ButtonEdit>
        </EditOptions>
      )}
    </Container>
  );
};

export default Note;
