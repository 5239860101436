import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Background = styled.div`
  width: 100%;
  min-height: 100%;
  background-image: url(${({ url }) => url});
  background-position: 50% 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
`;

export const Container = styled(GlobalContainer)`
  margin: 50px auto 100px;
  padding: 30px 20px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.23);
`;

export const TitleContainer = styled.div`
  margin: 0 0 60px;
`;

export const TitleText = styled.h1`
  margin: 0 0 10px;
  color: #333;
  font-size: 44px;
  line-height: 44px;
  font-weight: 300;
`;

export const TitleSpan = styled.div`
  width: 30px;
  height: 2px;
  padding-right: 0px;
  background-color: #fc4c02;
  color: #fc4c02;
`;

export const Sections = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const Section = styled.div`
  margin: 10px;
  padding: ${({ isActive }) => (isActive ? "30px" : "20px")} 30px;
  width: 200px;
  max-width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  background-color: ${({ isActive }) => (isActive ? "#00205b" : "#fc4c02")};
  box-shadow: 1px 1px 11px 0 #9f9f9f;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  )

  &:hover {
    box-shadow: 0 -1px 11px 0 #9f9f9f;
  }
`;

export const Bold = styled.div`
  font-size: 24px;
  font-weight: 900;
`;

export const Content = styled.div`
  margin: 20px;
  padding: 15px;
  width: 100%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
