import styled from "styled-components";
import { GlobalContainer } from "../../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)``;

export const Iframe = styled.iframe`
  width: 100%;
  height: 528px;

  @media screen and (max-width: 575px) {
    height: 400px;
  }
`;
