import React, { useState, useEffect } from "react";
import {
  getForumUrl,
  getFlarumCookie,
} from "../../services/endpoints/settings";
import {
  GlobalContainer,
  PageContainer,
  PageTitle,
} from "../../styles/GlobalStyles";
import { Container, Iframe } from "./styles";

const Forum = () => {
  const [forumUrl, setForumUrl] = useState("");

  useEffect(() => {
    const updateForumUrl = async () => {
      try {
        const allResponse = await Promise.all([
          getFlarumCookie(),
          getForumUrl(),
        ]);

        const responseForumCookie = allResponse[0];
        document.cookie = `${
          responseForumCookie.data.userCookie.split(";")[0]
        }; Domain=${process.env.REACT_APP_DOMAIN}`;
        document.cookie = `locale=pt-BR; Domain=${process.env.REACT_APP_DOMAIN}`;

        const responseForumUrl = allResponse[1];
        setForumUrl(responseForumUrl.value);
      } catch (err) {
        console.error(err);
      }
    };
    updateForumUrl();
  }, []);

  // const eventMethod = window.addEventListener
  //   ? "addEventListener"
  //   : "attachEvent";
  // const eventer = window[eventMethod];
  // const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

  // // Listen to message from fórum iframe
  // eventer(
  //   messageEvent,
  //   (e) => {
  //     if (e.data === "true" && window.innerWidth > 771) {
  //       window.scrollTo({
  //         top:
  //           document.body.scrollHeight || document.documentElement.ScrollHeight,
  //         behavior: "smooth",
  //       });
  //     }
  //   },
  //   false
  // );

  return (
    <PageContainer style={{ justifyContent: "flex-start" }}>
      <GlobalContainer>
        <PageTitle style={{ marginBottom: "35px" }}>Fórum</PageTitle>
      </GlobalContainer>
      {forumUrl && (
        <Container>
          <Iframe src={forumUrl} id='forum' title='forum' />
        </Container>
      )}
    </PageContainer>
  );
};

export default Forum;
