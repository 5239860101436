import React, { useState } from "react";
import { Container, Iframe } from "./styles";
import { PageTitle } from "../../../styles/GlobalStyles";

const LiveInternal = ({ content }) => {
  const [live] = useState((content && content[0]) || {});

  return (
    <Container>
      <PageTitle>Live</PageTitle>
      <Iframe
        src={(live.url && live.url.replace("watch?v=", "embed/")) || ""}
        frameborder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowfullscreen
        title='ReadMore'
      />
    </Container>
  );
};

export default LiveInternal;
