import React from "react";
import { useHistory } from "react-router-dom";
import { useExamContext } from "../../../../contexts/ExamProvider";
import { Strong, Title, Button } from "../styles";

const CourseMessage = ({
  exam,
  parentId,
  parentType,
  parentStatus,
  redirectUrl,
}) => {
  const history = useHistory();
  const {
    setExamId,
    setParentId,
    setParentType,
    setRedirectUrl,
  } = useExamContext();

  const handleClick = (event) => {
    event.preventDefault();

    setExamId(exam.id);
    setParentId(parentId);
    setParentType(parentType);
    setRedirectUrl(redirectUrl);
    history.push(`${history.location.pathname}/prova`);
  };

  return (
    <>
      {parentStatus.max_grade >= parentStatus.approval_percentage ? (
        <>
          <Title>
            <Strong>PARABÉNS!</Strong>
            <br />
            {"Você atingiu a nota mínima necessária para ser aprovado."}
          </Title>
          <Button onClick={(e) => history.push("/perfil")}>Perfil</Button>
        </>
      ) : parentStatus.attempts > 0 ? (
        <>
          <Title>
            <Strong>TENTE NOVAMENTE</Strong>
            <br />
            {"Você não atingiu a nota mínima necessária para ser aprovado."}
          </Title>
          <Button onClick={(e) => handleClick(e)}>Fazer Avaliação</Button>
        </>
      ) : (
        <>
          <Title>
            <Strong>PARABÉNS!</Strong>
            <br />
            {
              "Você concluiu 100% de todas as aulas neste curso. Para finalizar realize a avaliação."
            }
          </Title>
          <Button onClick={(e) => handleClick(e)}>Fazer Avaliação</Button>
        </>
      )}
    </>
  );
};

export default CourseMessage;
