import React, { useEffect, useState } from "react";
import { getCoursesCompleted } from "../../services/endpoints/lms/courses";
import { Container } from "./styles";
import { PageContainer, PageTitle } from "../../styles/GlobalStyles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
  Certificates,
  Spinner
} from "../../components/Components";

const PageCertificates = ({ match }) => {
  const [certificates, setCertificates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleCertificates = (certificates) => {
    const certificatesApproved = [];

    certificates.forEach((certificate) => {
      switch (true) {
        case certificate.mandatory_approval &&
          certificate.attempts &&
          certificate.max_grade >= certificate.approval_percentage:
          certificatesApproved.push(certificate);
          break;
        case !certificate.mandatory_approval:
          certificatesApproved.push(certificate);
          break;
        default:
          break;
      }
    });

   setCertificates(certificatesApproved || []);
  };

  useEffect(() => {
    setIsLoading(true);
    (async()=> {
      const watchedsResponse = await getCoursesCompleted();
      handleCertificates(watchedsResponse.data || []);
      setIsLoading(false);
    })()
  },[])

  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <PageContainer>
        <Container>
          <PageTitle>Certificados</PageTitle>
          {!isLoading ? <Certificates content={certificates} hasTitle={false} /> : <Spinner />}
        </Container>
      </PageContainer>
      <Footer />
    </>
  );
};

export default PageCertificates;
