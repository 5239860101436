import React, { useState } from "react";
import { Link } from "react-router-dom";
import { isExternalUrl } from "../../../utils";
import { redirectToLogout } from "../../../services/auth";
import { Container, LinkAnchor, Title } from "./styles";

const LinkHandler = ({
  url,
  target,
  title,
  children,
  childrenPosition,
  textColor,
  width,
}) => {
  const [urlLink] = useState(url || "#");
  const [targetLink] = useState(target || "_self");
  const [titleLink] = useState(title || "");

  const isLogoutLink = () => {
    return titleLink && titleLink.toLowerCase() === "sair";
  };

  return (
    <Container
      childrenPosition={childrenPosition}
      textColor={textColor}
      width={width}
    >
      {isLogoutLink() ? (
        <Link to='' target={targetLink} onClick={(e) => redirectToLogout()}>
          {titleLink && <Title>{titleLink}</Title>}
          {children}
        </Link>
      ) : isExternalUrl(url) ? (
        <LinkAnchor href={urlLink} target={targetLink}>
          {titleLink && <Title>{titleLink}</Title>}
          {children}
        </LinkAnchor>
      ) : (
        <Link to={urlLink} target={targetLink}>
          {titleLink && <Title>{titleLink}</Title>}
          {children}
        </Link>
      )}
    </Container>
  );
};

export default LinkHandler;
