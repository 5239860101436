import api from "../../../api";

export const getCommentsByLessonIdAndUserId = (lessonId, userId) => {
  return api.get(
    `/general/comments?user_iri=/users/${userId}&parent_iri=/lms/lessons/${lessonId}&order[created_at]=desc`
  );
};

export const updateCommentsById = (id, body) => {
  return api.put(`/general/comments/${id}`, { ...body });
};

export const deleteCommentsById = (id) => {
  return api.delete(`/general/comments/${id}`);
};

export const postComment = (body) => {
  api.post("/general/comments", { ...body });
};
