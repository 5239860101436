import React, { useState, useEffect } from "react";
import { getExams } from "../../services/endpoints/lms/exams";
import { useExamContext } from "../../contexts/ExamProvider";
import { Container, NoExam } from "./styles";
import { PageContainer } from "../../styles/GlobalStyles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
  Exam,
  Spinner,
} from "../../components/Components";

const PageExam = () => {
  const { examId, parentId, parentType, redirectUrl } = useExamContext();
  const [exam, setExam] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadExam = async () => {
      try {
        const { data } = await getExams(examId || "");
        setExam(data || {});
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    loadExam();
  }, [examId]);

  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <PageContainer>
        <Container>
          {!isLoading ? (
            <>
              {exam && parentId ? (
                <Exam
                  exam={exam}
                  parentId={parentId}
                  parentType={parentType}
                  redirectUrl={redirectUrl}
                />
              ) : (
                <NoExam>Prova indisponível no momento.</NoExam>
              )}
            </>
          ) : (
            <Spinner />
          )}
        </Container>
      </PageContainer>
      <Footer />
    </>
  );
};

export default PageExam;
