import React from "react";
import { Container, Icons, Icon } from "./styles";

const StarsTrail = ({ content }) => {
  const STARS = [1, 2, 3, 4, 5];
  const average = content.evaluations.length > 0
    ? (content.evaluations.reduce((prev, curr) => prev + curr.average, 0)) / content.evaluations.length
    : 5

  if (!content) return null;
  return (
    <Container>
      <Icons>
        {STARS.map((star) => (
          <Icon
            className={`${star <= average ? "fas" : "far"} fa-star`}
            key={`star-${star}`}
          />
        ))}
      </Icons>
    </Container>
  );
};

export default StarsTrail;
