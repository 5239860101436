import styled from "styled-components";

export const Container = styled.div`
  z-index: ${({ isPositionFixed }) => (isPositionFixed ? "9998" : "10000")};
  padding: 1px 0 0;
  min-height: 80px;
  position: ${({ isPositionFixed }) =>
    isPositionFixed ? "sticky" : "relative"};
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-bottom: ${({ isPositionFixed }) =>
    isPositionFixed ? "2px solid var(--secondary-color)" : "none"};

  @media screen and (max-width: 991px) {
    z-index: 100;
    position: sticky;
    border-bottom: 2px solid var(--secondary-color);
  }

  @media screen and (max-width: 575px) {
    right: 50px;
  }
`;

export const Navbar = styled.nav`
  min-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const centerElements = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
