import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AnimateHeight from "react-animate-height";
import { postExams } from "../../services/endpoints/lms/exams";
import { stripTags } from "../../utils/manipulateString";
import {
  Alternative,
  Alternatives,
  Button,
  ButtonSubmit,
  ExamForm,
  Progress,
  ProgressBar,
  ProgressLabel,
  Question,
  QuestionGroup,
  Questions,
} from "./styles";

const Exam = ({ exam, parentId, parentType, redirectUrl }) => {
  const history = useHistory();
  const [toogle, setToogle] = useState(exam.exam_questions[0].id);
  const [state, setState] = useState([]);
  const [enableFinish, setEnableFinish] = useState(false);

  const handleToogle = (id) => {
    setToogle((prevValue) => (prevValue !== id ? id : 0));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((old) => {
      const newState = old;

      if (newState.filter((item) => item.exam_questions_id === name).length) {
        newState.forEach((item, idx) => {
          if (item.exam_questions_id === name) {
            Object.assign(newState[idx], {
              exam_questions_alternative: value,
            });
          }
        });
      } else {
        newState.push({
          exam_questions_id: name,
          exam_questions_alternative: value,
        });
      }

      return newState;
    });

    if (state.length === exam.exam_questions.length) {
      setEnableFinish(true);
    } else {
      setEnableFinish(false);
    }
  };

  const nextQuestion = (idx) => {
    if (idx < exam.exam_questions.length - 1) {
      setToogle(exam.exam_questions[idx + 1].id);
    } else {
      setToogle(0);
    }
  };

  const onSubmit = async () => {
    try {
      await postExams(
        exam.id,
        `${parentType === "course" ? "course_id" : "lesson_id"}=${parentId}`,
        state
      );
      history.push(redirectUrl);
    } catch (e) {
      console.error("Exam.onSubmit", e);
      alert(
        "Ocorreu um erro ao enviar as respostas\nTente novamente mais tarde."
      );
    }
  };

  return (
    <ExamForm>
      <Questions>
        {exam.exam_questions.map((question, index) => (
          <QuestionGroup key={question.id}>
            <Question onClick={() => handleToogle(question.id)}>
              <div
                dangerouslySetInnerHTML={{
                  __html: stripTags(question.title) || "",
                }}
              />
              <i className='far fa-angle-down' />
            </Question>
            <AnimateHeight
              duration={500}
              height={question.id === toogle ? "auto" : 0}
            >
              <Alternatives>
                {question.exam_question_alternatives.map((alternative) => (
                  <Alternative
                    key={alternative.id}
                    htmlFor={`radio_question_${question.id}_${alternative.id}`}
                  >
                    <input
                      type='radio'
                      id={`radio_question_${question.id}_${alternative.id}`}
                      name={question.id}
                      value={alternative.id}
                      onChange={handleChange}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: alternative.title,
                      }}
                    />
                  </Alternative>
                ))}
              </Alternatives>
              <Button onClick={() => nextQuestion(index)}>
                Marcar alternativa
              </Button>
            </AnimateHeight>
          </QuestionGroup>
        ))}
      </Questions>

      {enableFinish && (
        <ButtonSubmit onClick={onSubmit}>Concluir avaliação</ButtonSubmit>
      )}

      <Progress>
        <ProgressLabel>Progresso</ProgressLabel>
        <ProgressBar
          width={Math.floor((state.length * 100) / exam.exam_questions.length)}
        />
      </Progress>
    </ExamForm>
  );
};

export default Exam;
