import React, { useCallback, useEffect, useState } from "react";
import { getTrails, getTrailsTotal } from "../../services/endpoints/lms/trails";
import {
  getCoursesCategoriesFromTrail,
  removeDuplicatesByProperty,
} from "../../utils";
import { Container, PageOptions } from "./styles";
import { PageContainer, PageTitle } from "../../styles/GlobalStyles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
  TrailsList,
  Pagination,
  Spinner,
} from "../../components/Components";
import TrailsSearch from "./TrailsSearch";
import TrailsCategories from "./TrailsCategories";

const PageTrails = () => {
  const [trails, setTrails] = useState([]);
  const [trailsTotal, setTrailsTotal] = useState(0);
  const [categories, setCategories] = useState([]);
  const [paginationDefault] = useState({
    perPage: 9,
    page: 0,
  });
  const [params, setParams] = useState({
    ...paginationDefault,
  });
  const [isLoading, setIsLoading] = useState(false);

  const fetchTrails = useCallback(async () => {
    try {
      const { data } = await getTrails(params);
      setTrails(data || []);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [params, setIsLoading]);

  const fetchTrailsTotal = useCallback(async () => {
    try {
      const { data } = await getTrailsTotal(params);
      setTrailsTotal(data || 0);
    } catch (err) {
      console.error(err);
    }
  }, [params]);

  const getTrailsCourses = useCallback((trails) => {
    if (trails && trails.length) {
      trails.forEach((trail) => {
        const trailCategories = getCoursesCategoriesFromTrail(
          trail.trails_courses
        );

        setCategories((categories) =>
          removeDuplicatesByProperty(
            [...categories, ...trailCategories],
            "title"
          )
        );

        trail["categories"] = trailCategories;
      });
    }
  }, []);

  useEffect(() => {
    getTrailsCourses(trails);
  }, [trails, getTrailsCourses]);

  useEffect(() => {
    setIsLoading(true);
    fetchTrails();
    fetchTrailsTotal();
  }, [params, fetchTrails, fetchTrailsTotal]);

  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <PageContainer>
        <Container>
          <PageTitle>Minhas Trilhas</PageTitle>
          <PageOptions>
            <TrailsSearch
              setParams={setParams}
              paginationDefault={paginationDefault}
              isLoading={isLoading}
            />
            <TrailsCategories
              categories={categories}
              setParams={setParams}
              paginationDefault={paginationDefault}
              isLoading={isLoading}
            />
          </PageOptions>

          {!isLoading ? <TrailsList content={trails} /> : <Spinner />}

          <Pagination
            setParams={setParams}
            paginationDefault={paginationDefault}
            totalItems={trailsTotal}
          />
        </Container>
      </PageContainer>
      <Footer />
    </>
  );
};

export default PageTrails;
