import Cookie from "js-cookie";

export const getToken = () => {
  return Cookie.get(process.env.REACT_APP_TOKEN);
};

export const redirectToLogout = (msg = "") => {
  localStorage.clear();
  sessionStorage.clear();
  Cookie.remove(process.env.REACT_APP_TOKEN);
  window.location = `${process.env.REACT_APP_SIGNIN_URL}/?logout`;
};
export const redirectToForbidden = (msg = "") => {
  window.location.pathname = `/403`;
};

export const redirectToPageNotFound = (msg = "") => {
  window.location.pathname = `/404`;
};

export const redirectToSignUp = (msg = "") => {
  localStorage.clear();
  Cookie.remove(process.env.REACT_APP_TOKEN);
  window.location = `${process.env.REACT_APP_SIGNIN_URL}/?signUp`;
};
