import React from "react";
import { Container } from "./styles";
import { Col } from "../../../../../styles/GlobalStyles";
import Description from "./Description";
import SupportMaterials from "./SupportMaterials";

const About = ({ lesson }) => {
  if (!lesson) return null;
  return (
    <Container>
      <Col width='50%'>
        <Description description={lesson.description} />
      </Col>
      <Col width='50%'>
        <SupportMaterials supportMaterials={lesson.lesson_support_materials} />
      </Col>
    </Container>
  );
};

export default About;
