import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NextArrow, PrevArrow, Dots } from "./styles";

const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return <PrevArrow className={className} onClick={onClick} />;
};

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return <NextArrow className={className} onClick={onClick} />;
};

const CustomSlider = ({ content = [] }) => {
  const settings = {

    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    appendDots: (dots) => <Dots>{dots}</Dots>,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
  };

  return (
    <Slider {...settings} style={{ width: "100%", maxWidth: "98vw" }}>
      {content.map((Item) => Item)}
    </Slider>
  );
};

export default CustomSlider;
