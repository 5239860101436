import React, { useEffect, useState } from "react";
import { Page } from "../styles";

const PagesDots = ({ pages, currentPage, setCurrentPage, limit }) => {
  const [prevPage, setPrevPage] = useState(null);
  const [nextPage, setNextPage] = useState(null);

  useEffect(() => {
    setPrevPage(currentPage - 1 >= 0 ? currentPage - 1 : null);
    setNextPage(currentPage + 1 < pages.length - 1 ? currentPage + 1 : null);
  }, [pages, currentPage]);

  const selectPage = (event) => {
    event.preventDefault();
    const selectedPage = window.prompt(`Páginas: 1-${pages.length}`);

    if (selectedPage > 0 && selectedPage <= pages.length) {
      setCurrentPage(selectedPage - 1);
    }
  };

  return (
    <>
      {currentPage < pages.length - 1 ? (
        <>
          {prevPage !== null && (
            <Page onClick={(e) => setCurrentPage(prevPage)}>
              {prevPage + 1}
            </Page>
          )}

          <Page isActive={true}>{currentPage + 1}</Page>

          {!!nextPage && (
            <Page onClick={(e) => setCurrentPage(nextPage)}>
              {nextPage + 1}
            </Page>
          )}

          <Page onClick={(e) => selectPage(e)}>...</Page>
        </>
      ) : (
        <>
          {prevPage !== null && (
            <Page onClick={(e) => setCurrentPage(prevPage)}>
              {prevPage + 1}
            </Page>
          )}
          <Page onClick={(e) => selectPage(e)}>...</Page>
          <Page isActive={true}>{currentPage + 1}</Page>
        </>
      )}
    </>
  );
};

export default PagesDots;
