import React from "react";
import { Container, Icon } from "./styles";

const Duration = ({ duration }) => {
  if (!duration) return null;
  return (
    <Container>
      <Icon className='far fa-clock' />
      {duration}
    </Container>
  );
};

export default Duration;
