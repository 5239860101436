/** @format */

import React, { useState } from "react";
import { Col } from "reactstrap";
import { BodyCard, EvaluationBar, Description, TitleInterna } from "./styles";
// import { LinkHandler } from "../Components";
import { GlobalContainer } from "../../styles/GlobalStyles";
import {
  Stars,
  FavoriteButton,
  CreditBar,
  Categories,
  Progress,
} from "./Components";
import { useEffect } from "react";
import { getContentMyRatingByContentIri } from "../../services/endpoints/general/evaluations";
import { getCurrentUser } from "../../utils";
import { useProgressContext } from "../../contexts/LMSProgressProvider";

const CardTrailInternal = ({ course }) => {
  const [evaluations, setEvaluations] = useState(null);
  const { updatedProgress, setUpdatedProgressParams } = useProgressContext();

  useEffect(() => {
    if (course.id) {
      setUpdatedProgressParams({ id: course.id });
    }
  }, [course.id, setUpdatedProgressParams]);

  useEffect(() => {
    (async () => {
      if (!evaluations && course.id) {
        const user = getCurrentUser();
        const { data } = await getContentMyRatingByContentIri(
          `/lms/users/${user.id}`,
          `/lms/courses/${course.id}`
        );

        setEvaluations(data);
      }
    })();
  }, [course, evaluations]);

  if (!course) return null;
  return (
    <GlobalContainer noTransform={true}>
      <BodyCard
        style={{
          padding: "15px 0 25px",
          backgroundColor: "transparent",
        }}
      >
        <Categories categories={course.categories} />

        {/* <LinkHandler url={`/curso-interno/${course.slug}`} childrenPosition='left' > */}
        <TitleInterna>{course.title || ""}</TitleInterna>
        {/* </LinkHandler> */}
        <Description dangerouslySetInnerHTML={{ __html: course.summary }} />

        <CreditBar
          createdBy={course.created_by}
          createdAt={course.created_at}
        />

        <EvaluationBar style={{ justifyContent: "flex-start" }}>
          <Col xs={12} lg={4} style={{ paddingLeft: "0" }}>
            <Stars
              content={{ ...course, evaluations }}
              contentIri={`/lms/courses/${course.id}`}
              title="Avalie este curso"
              style={{ justifyContent: "flex-start" }}
            />
          </Col>
          <Col xs={12} lg={4}>
            <Progress progress={updatedProgress.progress} />
          </Col>
          <Col xs={12} lg={3}>
            <FavoriteButton
              evaluations={course.evaluations}
              courseId={course.id}
              title="Adicionar aos favoritos"
              isEditable={true}
            />
          </Col>
        </EvaluationBar>
      </BodyCard>
    </GlobalContainer>
  );
};

export default CardTrailInternal;
