import api from "../../../api";

export const getCategories = (params) =>
  api.get(`/lms/categories`, {
    params: {
      ...params,
    },
  });

export const getCategoriesBySlug = (slug) => {
  return api.get(`/lms/categories?slug=${slug}`);
};

export const getCategoriesNotFromLibrary = (params) =>
  api.get(
    `/lms/categories?wherenotin[title]='Biblioteca'&exists[parent]=false`,
    {
      params: {
        ...params,
      },
    }
  );
