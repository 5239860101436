import React, { useState } from "react";
import { Container, Form, Input, Button } from "./styles";

const TrailsSearch = ({ setParams, paginationDefault, isLoading }) => {
  const [keyword, setKeyword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isLoading) return;

    if (keyword) {
      setParams((params) => ({
        ...params,
        ...paginationDefault,
        trails_search: `${keyword}`,
      }));
    } else {
      setParams((params) => ({
        ...params,
        ...paginationDefault,
        trails_search: undefined,
      }));
    }
  };

  return (
    <Container>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Input
          type='search'
          name='trailsSearch'
          placeholder='Pesquise cursos, empresas , etc..'
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Button type='submit' />
      </Form>
    </Container>
  );
};

export default TrailsSearch;
