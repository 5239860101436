import React from "react";

import { Container, Title, Text } from "./styles";

const Description = ({ description }) => {
  if (!description) return null;
  return (
    <Container>
      <Title>Descrição</Title>
      <Text dangerouslySetInnerHTML={{ __html: description }} />
    </Container>
  );
};

export default Description;
