import React from "react";
import ExamProvider from "./ExamProvider";
import CourseStatusProvider from "./CourseStatusProvider";
import NotificationProvider from "./NotificationProvider";

const Providers = ({ children }) => {
  return (
    <>
      <NotificationProvider>
        <CourseStatusProvider>
          <ExamProvider>{children}</ExamProvider>
        </CourseStatusProvider>
      </NotificationProvider>
    </>
  );
};

export default Providers;
