/** @format */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container } from "./styles";
import { useProgressContext } from "../../../contexts/LMSProgressProvider";

const Progress = ({ progress, label = "Progresso total:" }) => {
  return (
    <Container progress={progress} className="progress-container">
      <span>
        {label}
        <b id="total-progress">
          {" "}
          {`${Math.round(progress > 100 ? 100 : progress)} %` || "0.0 %"}{" "}
        </b>
      </span>
      <div className="ProgressBar">
        <div id="total-progress-count" />
      </div>
    </Container>
  );
};

export default Progress;
