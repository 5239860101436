import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "reactstrap";
import { getEndpoints } from "../../../services/endpoints";
import { PageTitle } from "../../../styles/GlobalStyles";
import { CardCourse } from "../../Components";

const CoursesFavorited = ({ content, hasTitle = true, setReload }) => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const loadCourses = async () => {
      try {
        const endpoints = [];
        content.forEach((favorite) => {
          endpoints.push(getEndpoints(favorite.content_iri));
        });
        const response = (await Promise.all(endpoints)).map((res) => res.data);

        response.forEach((course) => {
          course.evaluations[0].userEvaluationValue = 1;
        });
        setCourses(response);
      } catch (err) {
        console.error(err);
      }
    };

    if (content) {
      loadCourses();
    }
  }, [content]);

  if (!content || !content.length) return null;
  return (
    <Container>
      {hasTitle && <PageTitle>Favoritos</PageTitle>}
      <Row>
        {courses.map((course) => (
          <Col xs='12' lg='4' key={`favorite-${course.id}`}>
            <CardCourse course={course} setReload={setReload} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CoursesFavorited;
