import styled from "styled-components";

export const Container = styled.div`
  height: 450px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  & > div {
    padding-right: 0px;
  }

  @media screen and (max-width: 991px) {
    margin-top: 10px;
    & > div {
      padding-right: 15px;
    }
  }
`;
