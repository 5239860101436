import React, { useState } from "react";
import { Container, Icon, NavLink, arrowIcon, Dropdown } from "./styles";
import { LinkHandler } from "../../../Components";
import DropdownLink from "./DropdownLink";

const MenuDropdownLink = ({ dropdown = {} }) => {
  const [icon] = useState(dropdown.icon || "");
  const [title] = useState(dropdown.title || "");
  const [dropdownItems] = useState(dropdown.menu_items || []);
  const [isVisible, setIsVisible] = useState(false);
  if (!dropdown) return null;

  return (
    <Container>
      <NavLink onClick={(e) => setIsVisible((prev) => !prev)}>
        <Icon src={icon} alt='' />
        <LinkHandler title={title} textColor='#fff' />
        <i className='fas fa-chevron-down fa' style={arrowIcon} />
      </NavLink>

      <Dropdown isVisible={isVisible}>
        {dropdownItems.map((dropdownItem) => (
          <DropdownLink
            link={dropdownItem}
            key={`dropdownItem-${dropdownItem.id}`}
          />
        ))}
      </Dropdown>
    </Container>
  );
};

export default MenuDropdownLink;
