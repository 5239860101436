import React, { useState } from "react";
import { Container, Banner, Title } from "./styles";
import { CustomSlider, ArrowButton } from "../Components";
import NoBanner from "../../assets/images/placeholder.jpg";

const BannersSlider = ({ content }) => {
  const [banners] = useState(content.banners || []);

  return (
    <Container>
      {banners && banners.length && (
        <CustomSlider
          content={banners.map((banner) => (
            <Banner
              href={banner.url || ''}
              url={(banner.file && banner.file.urlPublica) || NoBanner}
              key={`banner-${banner.id}`}
            >
              <Title>{banner.title || ""}</Title>
              {banner.button_text && (
                <ArrowButton
                  url={banner.url}
                  target={banner.target}
                  title={banner.button_text}
                  position='left'
                  color='#fff'
                />
              )}
            </Banner>
          ))}
        />
      )}
    </Container>
  );
};

export default BannersSlider;
