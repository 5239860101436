import React, { useCallback, useEffect, useState } from "react";
import { getSupportMaterialsBySlug } from "../../services/endpoints/lms/supportMaterials";
import { Container } from "./styles";
import { PageContainer } from "../../styles/GlobalStyles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
  Spinner,
  LibraryInternal,
} from "../../components/Components";

const PageLibraryInternal = ({ match }) => {
  const [slug] = useState(match.params.slug);
  const [library, setLibrary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchLibrary = useCallback(async () => {
    try {
      const { data } = await getSupportMaterialsBySlug(slug);
      setLibrary(data || []);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [slug]);

  useEffect(() => {
    setIsLoading(true);
    fetchLibrary();
  }, [fetchLibrary]);

  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <PageContainer>
        <Container>
          {!isLoading ? <LibraryInternal content={library} /> : <Spinner />}
        </Container>
      </PageContainer>
      <Footer />
    </>
  );
};

export default PageLibraryInternal;
