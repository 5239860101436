/** @format */

import React, { memo } from "react";
import SambaVideo from "./SambaVideo";
import VideoFile from "./VideoFile";
import FilePDF from "./FilePDF";
import FileZIP from "./FileZIP";
import ImageFile from "./ImageFile";
import Scorm from "./Scorm";
import { getCurrentUser } from "../../../utils";

const ContentMedia = ({
  content,
  position,
  courseId,
  updateStats = () => {},
  setLessonStatus = null,
  lessonContent = null,
  setProgressLesson,
}) => {
  const Medias = () => {
    switch (content.type) {
      case "SAMBA_MEDIA":
        return (
          <SambaVideo
            userIri={`/users/${getCurrentUser().id}`}
            contentIri={`/lms/lesson_contents/${content.id}`}
            reference={content.samba_media_reference || ""}
            courseId={courseId}
            lessonId={lessonContent ? lessonContent.id : null}
            setProgressLesson={setProgressLesson}
          />
        );
      case "FILE_SCORM":
        content["file_scorm"] = content.file;
        return (
          <Scorm
            content={content}
            contentType="lesson"
            courseId={courseId}
            contentIri={`/lms/lesson_contents/${content.id}`}
            setProgressLesson={setProgressLesson}
          />
        );
      case "FILE":
        if (
          content.file.contenttype === "image/jpeg" ||
          content.file.contenttype === "image/png" ||
          content.file.contenttype === "image/jpg"
        ) {
          return (
            <ImageFile
              updateStats={updateStats}
              src={content.file.urlPublica}
              alt={content.title}
            />
          );
        } else if (content.file.contenttype === "application/pdf") {
          return (
            <FilePDF
              userIri={`/users/${getCurrentUser().id}`}
              content={content}
              contentIri={`/lms/lesson_contents/${content.id}`}
            />
          );
        } else if (
          content.file.contenttype === "application/x-zip-compressed"
        ) {
          return (
            <FileZIP
              userIri={`/users/${getCurrentUser().id}`}
              content={content}
              contentIri={`/lms/lesson_contents/${content.id}`}
            />
          );
        } else if (
          content.file.contenttype === "video/mp4" ||
          content.file.contenttype === "video/avi"
        ) {
          return (
            <VideoFile
              updateStats={updateStats}
              id={content.id}
              url={content.file.urlPublica}
            />
          );
        }
        break;
      default:
        return content.file ? content.file.urlPublica : "";
    }
  };

  return (
    <div className="medias">
      <Medias />
    </div>
  );
};

export default memo(ContentMedia);
