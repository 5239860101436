import React, { useEffect, useState } from "react";
import { stripTags } from "../../../../utils/manipulateString";
import { isDateExpired } from "../../../../utils";
import { Container, Span, Image, Title, Blob } from "./styles";
import Placeholder from "../../../../assets/images/placeholder-live.jpg";
import { ArrowButton, LinkHandler } from "../../../Components";

const Live = ({ live }) => {
  const [isExpired, setIsExpired] = useState();

  useEffect(() => {
    setIsExpired(isDateExpired(live.ended_at));
  }, [live]);

  if (!live) return null;
  return (
    <Container>
      {!isExpired && <Blob />}

      <Span>{isExpired ? "FINALIZADA" : "LIVE"}</Span>

      <LinkHandler url={!isExpired ? `/live-interna/${live.id}` : ""}>
        <Image
          src={(live.file && live.file.urlPublica) || Placeholder}
          alt='thumbnail da live'
          isExpired={isExpired}
        />
      </LinkHandler>

      <LinkHandler url={!isExpired ? `/live-interna/${live.id}` : ""}>
        <Title dangerouslySetInnerHTML={{ __html: stripTags(live.title) }} />
      </LinkHandler>

      {!isExpired && (
        <ArrowButton
          url={!isExpired ? `/live-interna/${live.id}` : ""}
          title='PARTICIPAR'
          position='left'
        />
      )}
    </Container>
  );
};

export default Live;
