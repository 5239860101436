import styled from "styled-components";

export const Container = styled.div`
  margin: 10px 0;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Icon = styled.img`
  margin: 0 10px 0 0;
  width: 28px;
`;

export const NavLink = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &:hover {
    a,
    i {
      color: var(--secondary-color) !important;
    }
  }

  & div a {
    padding: 0 5px 0 0;
    text-decoration: none;
    font-size: 15px;
    color: #fff;
  }
`;

export const arrowIcon = {
  padding: "2px 0 0",
  color: "#fff",
  fontSize: "13px",
  lineHeight: "1px",
  textAlign: "center",
};

export const Dropdown = styled.div`
  z-index: 1000;
  padding: 5px 8px;
  width: 100%;
  position: relative;
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);

  & a,
  i {
    color: #fff !important;
  }
`;
