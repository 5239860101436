import React, { useState, useEffect } from "react";
import { getEndpoints } from "../services/endpoints";
import { snakeToPascalCase } from "../utils/manipulateString";
import { ComponentFactory, Spinner } from "./Components";
import { Main, Header, Footer } from "../styles/GlobalStyles";

const ComponentBase = ({ children, page }) => {
  const [containers, setContainers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAllEndpoints = (blocks) => {
    const endPoints = [];
    blocks.forEach((block) => {
      endPoints.push(getEndpoints(block.block.endpoint));
    });

    return endPoints;
  };

  const saveContainers = (blocks, resultEndPoints) => {
    blocks.forEach((block, index) => {
      const blockContent =
        resultEndPoints.length > 0 && resultEndPoints[index]
          ? resultEndPoints[index].data
          : {};
      const { container } = block;
      const componentName = snakeToPascalCase(block.block.name);
      const content = {
        container: container,
        componentName: componentName,
        content: blockContent,
        title: page.pages_blocks[index].block.title,
      };

      setContainers((containers) => [...containers, content]);
    });
  };

  useEffect(() => {
    const loadComponents = async () => {
      let resultEndPoints = [];
      try {
        const endPoints = getAllEndpoints(page.pages_blocks);
        resultEndPoints = (await Promise.all(endPoints)) || [];
        saveContainers(page.pages_blocks, resultEndPoints);
        setIsLoading(false);
      } catch (err) {
        saveContainers(page.pages_blocks, resultEndPoints);
        setIsLoading(false);
        console.error(err);
      }
    };

    setIsLoading(true);
    loadComponents();
    // eslint-disable-next-line
  }, [page]);

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Header>
        {containers
          .filter(({ container }) => container.toLowerCase() === "header")
          .map(({ componentName, content, title }, index) => (
            <React.Fragment key={`header-container-${index}`}>
              {componentName && content && (
                <ComponentFactory
                  key={`header-${index}`}
                  componentName={componentName}
                  content={content}
                  title={title}
                  page={page}
                />
              )}
            </React.Fragment>
          ))}
      </Header>

      <Main>
        {containers
          .filter(({ container }) => container.toLowerCase() === "main")
          .map(({ componentName, content, title }, index) => (
            <React.Fragment key={`main-container-${index}`}>
              {componentName && content && (
                <ComponentFactory
                  key={`main-${index}`}
                  componentName={componentName}
                  content={content}
                  title={title}
                  page={page}
                />
              )}
            </React.Fragment>
          ))}
      </Main>

      {children}

      <Footer>
        {containers
          .filter(({ container }) => container.toLowerCase() === "footer")
          .map(({ componentName, content, title }, index) => (
            <React.Fragment key={`footer-container-${index}`}>
              {componentName && content && (
                <ComponentFactory
                  key={`footer-${index}`}
                  componentName={componentName}
                  content={content}
                  title={title}
                  page={page}
                />
              )}
            </React.Fragment>
          ))}
      </Footer>
    </>
  );
};

export default ComponentBase;
