import React, { useState } from "react";
import { Container, Icon, NavLink, arrowIcon, Dropdown } from "./styles";
import { LinkHandler } from "../../Components";
import DropdownLink from "./DropdownLink";

const NavbarDropdown = ({ dropdown }) => {
  const [icon] = useState(
    (dropdown.icon_image && dropdown.icon_image.urlPublica) || ""
  );
  const [title] = useState(dropdown.title || "");
  const [dropdownItems] = useState(dropdown.menu_items || []);

  if (!dropdown) return null;
  return (
    <Container>
      <Icon src={icon} alt='' />

      <NavLink>
        <LinkHandler title={title} textColor='#FFFFFF' />
        <i className='fas fa-chevron-down fa' style={arrowIcon} />
      </NavLink>

      <Dropdown>
        {dropdownItems.map((dropdownItem) => (
          <DropdownLink
            link={dropdownItem}
            key={`dropdownItem-${dropdownItem.id}`}
          />
        ))}
      </Dropdown>
    </Container>
  );
};

export default NavbarDropdown;
