import React from "react";
import { BodyCard, EvaluationBar, Title } from "./styles";
import { GlobalContainer, Col } from "../../styles/GlobalStyles";
import { Stars, Categories } from "./Components";
import { LinkHandler, ArrowButton } from "../Components";
import HeaderLibrary from "./HeaderLibrary";

const CardLibrary = ({ lib }) => {
  if (!lib) return null;

  return (
    <GlobalContainer noTransform={true}>
      <LinkHandler url={`/biblioteca-interna/${lib.slug}`}>
        <HeaderLibrary image={lib.image ? lib.image.urlPublica : ""} />
      </LinkHandler>

      <BodyCard style={{ backgroundColor: "transparent" }}>
        <EvaluationBar>
          <Col width='40%'>
            <Stars
              content={lib}
              contentIri={`/lms/support_materials/${lib.id}`}
            />
          </Col>
          <Col width='60%'>
            <Categories
              categories={lib.support_material_categories.map(
                ({ category }) => category
              )}
            />
          </Col>
        </EvaluationBar>
        <LinkHandler
          url={`/biblioteca-interna/${lib.slug}`}
          childrenPosition='center'
        >
          <Title>{lib.title || ""}</Title>
        </LinkHandler>
        <ArrowButton
          url={`/biblioteca-interna/${lib.slug}`}
          target='_blank'
          title='VER MAIS'
          position='center'
          color='#333'
        />
      </BodyCard>
    </GlobalContainer>
  );
};

export default CardLibrary;
