/** @format */

import React, { useState } from "react";
import { Container } from "./styles";
import Lesson from "./Lesson";

const Lessons = ({ lessons, courseId, setCourseStatus, setLessonStatus }) => {
  const [currentOpenLesson, setCurrentOpenLesson] = useState(-1);

  if (!lessons || !lessons.length) return null;
  return (
    <Container>
      {lessons.map((lesson, index) => (
        <Lesson
          courseId={courseId}
          lesson={lesson}
          lessonPosition={index}
          currentOpenLesson={currentOpenLesson}
          setCurrentOpenLesson={setCurrentOpenLesson}
          key={`lesson-${lesson.id}`}
          setCourseStatus={setCourseStatus}
          setLessonStatus={setLessonStatus}
        />
      ))}
    </Container>
  );
};

export default Lessons;
