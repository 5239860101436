import styled from "styled-components";
import { GlobalContainer } from "../../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 65vh;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  margin: 0 35px 35px 0;
  width: 30%;
  height: auto;

  @media screen and (max-width: 575px) {
    margin: 0 0 35px 0;
    width: 100%;
  }
`;

export const Info = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const Text = styled.div`
  margin: 0 0 30px;
  font-size: 17px;
  line-height: 28px;
`;

export const Button = styled.a`
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
`;

export const Icon = styled.i`
  margin: 0 10px;
  color: var(--secondary-color);
`;
