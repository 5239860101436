import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto 20px;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.03);
  border-style: solid solid none;
  border-color: rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  transition: all 0.4s ease-in;
`;

export const CreateNoteNowButton = styled.div`
  padding: 10px 16px;
  width: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-size: 14px;
  border-radius: 6px;
  background-color: var(--secondary-color);
  cursor: pointer;
`;

export const CreateNoteForm = styled.form`
  width: 100%;
  height: 100%;
  max-height: ${({ isVisible }) => (isVisible ? "100%" : "0")};

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const CreateNoteTitle = styled.input`
  margin: 15px 0 0;
  padding: 6px 14px;
  width: 100%;
  border-radius: 6px;
  background-color: #f5f5f5;
  color: #4f5258;
  font-weight: 900;
`;

export const CreateNoteDescription = styled.input`
  margin: 15px 0 0;
  padding: 40px 14px;
  width: 100%;
  background-color: #f5f5f5;
  color: #4f5258;
  border-radius: 6px;
  font-weight: 900;
`;

export const CreateNoteButton = styled.button`
  margin: 15px 0;
  padding: 10px 16px;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: var(--secondary-color);
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: var(--secondary-color);
    color: white;
  }
`;
