import React from "react";
import { GlobalContainer } from "../../styles/GlobalStyles";
import { Container, Title, Icon } from "./styles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
} from "../../components/Components";

const PageNotFound = () => {
  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <GlobalContainer>
        <Container>
          <Title>Página não encontrada</Title>
          <Icon className='far fa-frown-open' />
        </Container>
      </GlobalContainer>
      <Footer />
    </>
  );
};

export default PageNotFound;
