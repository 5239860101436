import React from "react";
import { Container, Col, Row } from "reactstrap";
import { PageTitle } from "../../../styles/GlobalStyles";
import { CardOngoingCourse } from "../../Components";

const CoursesOngoing = ({ content, hasTitle = true }) => {
  if (!content || !content.length) return null;
  // eslint-disable-next-line
  content.map((course) => {
    if (course.evaluations) {
      return Object.assign(course.course, { evaluations: course.evaluations });
    }
  });

  return (
    <Container>
      {hasTitle && (
        <PageTitle>
          Cursos em
          <br /> andamento
        </PageTitle>
      )}
      <Row>
        {content.map((course) => (
          <Col xs='12' lg='4' key={course.id}>
            <CardOngoingCourse
              course={course.course}
              progress={course.progress}
              progressStr={course.progressStr}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CoursesOngoing;
