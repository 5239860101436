import React from "react";
import { formatPosition } from "../../../../../utils/manipulateString";
import {
  Container,
  Thumbnail,
  ThumbIcon,
  Info,
  Position,
  Duration,
  CourseTitle,
} from "./styles";
import Placeholder from "../../../../../assets/images/placeholder.jpg";

const Course = ({
  course,
  coursePosition,
  courses,
  setActualCoursePosition,
}) => {
  const getCourseStats = (coursePosition) => {
    return (
      courses &&
      courses[coursePosition] &&
      courses[coursePosition].course &&
      courses[coursePosition].course.course_stats &&
      courses[coursePosition].course.course_stats[0] &&
      courses[coursePosition].course.course_stats[0].course_status
    );
  };

  const handleIconType = (coursePosition, previousCoursePosition) => {
    const courseStats = getCourseStats(coursePosition);
    const previousCourseStats = getCourseStats(previousCoursePosition);

    switch (true) {
      case coursePosition === 0 && courseStats === "CONCLUIDO":
        return "fas fa-check-circle";

      case coursePosition === 0 || courseStats === "EM_ANDAMENTO":
        return "fas fa-eye";

      case courseStats === "CONCLUIDO":
        return "fas fa-check-circle";

      case previousCourseStats === "CONCLUIDO" &&
        courseStats === "EM_ANDAMENTO":
        return "far fa-check-circle";

      case previousCourseStats === "CONCLUIDO":
        return "far fa-check-circle";

      default:
        return "fas fa-lock";
    }
  };

  const handleClick = (event, coursePosition, previousCoursePosition) => {
    event.preventDefault();
    const courseStats = getCourseStats(coursePosition);
    const previousCourseStats = getCourseStats(previousCoursePosition);

    if (
      coursePosition === 0 ||
      previousCourseStats === "CONCLUIDO" ||
      courseStats === "CONCLUIDO"
    ) {
      setActualCoursePosition(coursePosition);
    }
  };

  if (!course) return null;
  return (
    <Container>
      <Thumbnail
        url={(course.image && course.image.urlPublica) || Placeholder}
        onClick={(e) => handleClick(e, coursePosition, coursePosition - 1)}
      >
        <ThumbIcon
          className={handleIconType(coursePosition, coursePosition - 1)}
        />
      </Thumbnail>

      <Info>
        <Position>
          Curso {formatPosition(coursePosition + 1)} de {courses.length}
        </Position>
        <Duration>{course.duration || "00:00"}</Duration>
        <CourseTitle dangerouslySetInnerHTML={{ __html: course.title || "" }} />
      </Info>
    </Container>
  );
};

export default Course;
