export class ScormApi {
  state = {
    'cmi.core.lesson_location': '0',
    'cmi.core.student_id': '0',
  }
  error = {}
  lastError = 0
  listener

  constructor(listener = () => {}, raw = "0", lesson_status = "not attempted", lesson_location = "0") {
    this._defineState({
      raw,
      lesson_status,
      lesson_location
    })
    this._defineError()
    this.listener = listener
  }

  _defineState ({ raw, lesson_status, lesson_location }) {
    this.state["cmi.suspend_data"] = {}
    this.state["cmi.core.score.raw"] = raw
    this.state["cmi.core.lesson_status"] = lesson_status
    this.state["cmi.core.lesson_location"] = lesson_location
    this.state["cmi.core.student_name"] = 'scorm'
  }
  
  _defineError () {
    this.error["0"] = "No error"
    this.error["101"] = "General Exception"
    this.error["201"] = "Invalid Argument Error"
    this.error["202"] = "Element cannot have children"
    this.error["203"] = "Element not an array.  Cannot have count"
    this.error["301"] = "Not initialized"
    this.error["401"] = "Not implemented error"
    this.error["402"] = "Invalid set value, element is a keyword"
    this.error["403"] = "Element is read only"
    this.error["404"] = "Element is write only"
    this.error["405"] = "Incorrect Data Type"
  }

  LMSInitialize() {
    this.listener("Initialize")
    return true
  }

  LMSFinish() {
    this.listener("Finished");
    return true;
  }

  LMSGetValue(index) {
    this.listener('GetValue', this.state[index])
    if (!this.state[index]) {
      console.error(`LMSGetValue: index not found ${index}`)
    }
    return this.state[index]
  }

  LMSSetValue(name, value) {
    this.state = { ...this.state, ...{ [name]: value } }
    this.listener("SetValue", this.state)
    return value
  }

  LMSCommit() {
    this.listener("Commit")
    return true
  }

  LMSGetLastError() {
    return this.lastError
  }

  LMSGetDiagnostic(errorCode) {
    this.listener("Diagnostic", errorCode);
    return errorCode;
  }

  LMSGetErrorString(errorCode) {
    console.error("ERROR IN THE SCORM API: ", errorCode);
    return errorCode;
  }
}
