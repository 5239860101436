import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { getCurrentUser } from "../../utils";
import { Container, FormContainer } from "./styles";
import AvatarUpload from "./AvatarUpload";
import ProfileForm from "./ProfileForm";

const Profile = () => {
  const [user] = useState(getCurrentUser() || {});
  const [profile] = useState((user && user.profile) || {});

  return (
    <Container>
      <Row>
        <Col xs='12' lg='3'>
          <AvatarUpload user={user} />
        </Col>
        <Col xs='12' lg='9'>
          <FormContainer>
            <ProfileForm user={user} profile={profile} />
          </FormContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
