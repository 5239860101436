import React from "react";
import { Container, Col, Row } from "reactstrap";
import { PageTitle } from "../../../styles/GlobalStyles";
import { CardCourse, ArrowButton } from "../../Components";

const CoursesInHighlight = ({ content, hasTitle = true }) => {
  if (!content || !content.length) return null;
  return (
    <Container>
      {hasTitle && <PageTitle>Cursos em Destaque</PageTitle>}
      <Row>
        {content.map((course) => (
          <Col xs='12' lg='4' key={course.id}>
            <CardCourse course={course} />
          </Col>
        ))}
      </Row>

      <ArrowButton
        url='/cursos'
        title='MAIS CURSOS'
        position='right'
        color='#333'
      />
    </Container>
  );
};

export default CoursesInHighlight;
