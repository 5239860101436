import styled from "styled-components";

export const Container = styled.div`
  margin: 0 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img`
  width: 28px;
  max-width: 100%;
  max-height: 100%;
`;

export const NavLink = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    a,
    i {
      color: var(--secondary-color) !important;
    }
  }

  & div a {
    padding: 0 5px 0 0;
    text-decoration: none;
    font-size: 15px;
    color: #fff;
  }
`;

export const arrowIcon = {
  padding: "2px 0 0",
  color: "#fff",
  fontSize: "13px",
  lineHeight: "1px",
  textAlign: "center",
};

export const Dropdown = styled.div`
  z-index: 1000;
  padding: 5px 8px;
  width: 230px;
  position: absolute;
  top: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.21);
  border-radius: 4px;

  ${Container}:hover & {
    display: flex;
  }
`;
