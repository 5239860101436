import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto 60px;
  padding: 20px 0 20px 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  border-style: solid;
  border-width: 1px;
  border-color: #eaeaea;
  border-radius: 6px;

  @media screen and (max-width: 575px) {
    padding: 20px 10px;
  }
`;

export const FormContainer = styled.div`
  h1 {
    margin: 20px 0 10px;
    color: #211e2a;
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
    text-align: center;
  }

  .group-field {
    padding: 0 0 0 60px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    border-left: 1px solid rgba(79, 82, 88, 0.23);

    @media screen and (max-width: 991px) {
      padding: 0;
      border-left: none;
    }
  }

  .element-field {
    width: 100%;
    max-width: 250px;
    margin: 10px 0;

    .label-title {
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
    }

    .form-control {
      padding: 8px 12px;
      width: 100%;
      height: 48px;
      border-radius: 50px;
      border: 1px solid #dcdada;
      font-size: 14px;
      line-height: 1.42857143;
      color: #333333;
      background-color: #ffffff;

      &:focus {
        border-color: #3898ec;
        outline: 0;
      }
    }

    span {
      color: #f00;
      font-size: 12px;
    }
  }

  .footer-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .btn {
    margin: 20px 0 0;
    width: 100%;
    max-width: 250px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50px;
    background-color: #00205b;
    border-color: transparent;
    line-height: 14px;
    text-transform: uppercase;
    cursor: pointer;
    transition: transform 500ms ease;

    &:hover {
      transform: translate(0px, -5px);
    }

    @media screen and (max-width: 608px) {
      max-width: 100%;
    }
  }
`;

export const Info = styled.div`
  padding: 0 0 0 60px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  border-left: 1px solid rgba(79, 82, 88, 0.23);

  & div {
    padding: 10px;
  }

  @media screen and (max-width: 991px) {
    padding: 0;
    border-left: none;
  }
`;

export const Field = styled.div`
  width: 50%;
  color: #333;

  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;
export const Label = styled.strong`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
`;
export const Text = styled.h4`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  white-space: nowrap;
`;
