import React, { useState, useEffect } from "react";
import { NewTestimonialBox, NewTestimonialInput, TestimonialsBox } from "./styles";
import { GlobalContainer } from "../../../styles/GlobalStyles";
import { Row, Col } from "reactstrap";
import TestimonialsItem from "./TestimonialsItem";
import { getTestimonials, createTestimonial } from "../../../services/endpoints/cms/testimonials";
import { getCurrentUser } from "../../../utils";
import NoProfilePic from "../../../assets/images/no-profile-picture-icon.jpg";

const CourseTestimonials = ({ course }) => {
  const [testimonials, setTestimonials] = useState([])
  const [newTestimonial, setNewTestimonial] = useState('')
  const [textButton, setTextButton] = useState('Enviar')
  const user = getCurrentUser()
  
  const currentAvatar = user?.profile?.avatar?.file?.urlPublica || NoProfilePic

  useEffect(() => {
    (async() => {
      const { data } =  await getTestimonials(course.id);
      setTestimonials(data)
    })()
  }, [course.id]);

  const handleChange = (text) => {
    if(text.length <= 150){
      setNewTestimonial(text)
    }
  }

  const submitTestimonial = async() => {
    if(!!newTestimonial){
      setTextButton('Enviando...')
      try {
        const {data} =  await createTestimonial({
          user_iri: `/users/${user.id}`,
          title: user.name,
          description: newTestimonial,
          content_iri: `/lms/courses/${course.id}`,
          publish: true,
        })
        setTestimonials(prev => ([data, ...prev]))
        setNewTestimonial('')
        setTextButton('Enviado')
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <GlobalContainer>
      <TestimonialsBox>
        <h2>Depoimentos</h2>
        <NewTestimonialBox mb={!!testimonials.length}>
          <img src={currentAvatar} alt="Avatar do usuário atual"/>
          <NewTestimonialInput>
            <textarea
              value={newTestimonial}
              onChange={e => handleChange(e.target.value)}
              placeholder='Escreva seu depoimento'
            />
            <button onClick={submitTestimonial}>{textButton}</button>
          </NewTestimonialInput>
        </NewTestimonialBox>

        <Row>
          {!!testimonials.length && testimonials.map(item => (
            <Col key={item.id} xs={12} lg={3}>
              <TestimonialsItem content={item}/>
            </Col>
          ))}
        </Row>
      </TestimonialsBox>
    </GlobalContainer>
  );
};

export default CourseTestimonials;
