import React from "react";
import { Container, Col, Row } from "reactstrap";
import { ButtonStyled } from "./styles";

const Button = ({ title, description, link }) => {
  return (
    <ButtonStyled to={link}>
      <h3>{title}</h3>
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </ButtonStyled>
  );
};

const HomeButtons = ({ content }) => {
  return (
    <Container>
      <Row className="my-4">
        <Col xs={12} lg={6} className="my-2">
          <Button
            title="Certificados"
            description="Acesse agora e baixe todos os certificados dos cursos concluídos "
            link="/certificados"
          />
        </Col>
        <Col xs={12} lg={6} className="my-2">
          <Button
            title="Fale conosco"
            description="Sugestões ou dúvidas? <br/>Clique aqui e preencha o formulário."
            link="/contato"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default HomeButtons;
