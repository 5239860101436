import styled from "styled-components";

export const Container = styled.div`
  margin: 0 0 20px;
  padding: 9px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
`;

export const Image = styled.img`
  margin: 0 14px 0 0;
  width: 32px;
  height: 32px:
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Text = styled.p`
  color: var(--primary-color);
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
`;

export const Bold = styled.b``;

export const Date = styled.p`
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
`;
