import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Span = styled.div`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
  font-size: 40px;
  color: #fff;
  z-index: 10;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  position: relative;

  &:hover {
    opacity: ${({ isExpired }) => (isExpired ? "1" : "0.8")};
  }
`;

export const Title = styled.p`
  margin-top: 10px;
  margin-bottom: 0px;
  color: #333;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const Blob = styled.div`
  margin: 10px;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  background: #ea353d;
  z-index: 10;

  box-shadow: 0 0 0 0 rgba(234, 53, 61, 1);
  transform: scale(1);
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(234, 53, 61, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(234, 53, 61, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(234, 53, 61, 0);
    }
  }
`;
