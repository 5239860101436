import React from "react";
import { Container, Icon } from "./styles";
import IconImage from "../../../assets/images/right-arrow.svg";
import { LinkHandler } from "../../Components";

const ArrowButton = ({ url, target, title, position, color }) => {
  return (
    <Container position={position}>
      <LinkHandler url={url} target={target} title={title} textColor={color}>
        <Icon src={IconImage} alt='' />
      </LinkHandler>
    </Container>
  );
};

export default ArrowButton;
