/** @format */

import React, { useState, createContext, useContext } from "react";

const CourseStatusContext = createContext();

export const useCourseStatusContext = () => useContext(CourseStatusContext);

const CourseStatusProvider = ({ children }) => {
  const [courseStatusUpdated, setCourseStatusUpdated] = useState(false);

  const updateCourseStatus = () => {
    return setTimeout(() => {
      setCourseStatusUpdated(new Date().toLocaleString());
    }, 1500);
  };

  return (
    <CourseStatusContext.Provider
      value={{ courseStatusUpdated, updateCourseStatus }}
    >
      {children}
    </CourseStatusContext.Provider>
  );
};

export default CourseStatusProvider;
