/** @format */

import styled from "styled-components";
import { Link } from "react-router-dom";

export const ButtonStyled = styled(Link)`
  display: block;
  height: 140px;
  padding: 20px 35px;
  border-radius: 3px;
  background-color: #00205b;
  box-shadow: 0 2px 13px -8px #646262;
  transition: box-shadow 400ms ease, transform 400ms ease;
  color: #fff;
  text-decoration: none;
  transition: box-shadow 400ms ease, transform 400ms ease,
    -webkit-transform 400ms ease;

  &:hover {
    border: 1px solid transparent;
    background-color: var(--secondary-color);
    box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
    transform: translate(0px, -6px) scale(1.02);
  }

  h3 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    text-transform: uppercase;
  }
`;
