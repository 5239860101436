import styled from "styled-components";

export const Container = styled.div`
  // margin: 0 20px;
  width: 100%;
  height: 100%;
  max-width: 150px;

  & a {
    width: 100%;
    height: 100%;
  }
`;

export const Imagem = styled.img`
  width: 100%;
  height: auto;
`;
