import React from "react";
import { Container, BodyCard, EvaluationBar, Title } from "./styles";
import { Col } from "../../styles/GlobalStyles";
import {
  HeaderCard,
  Duration,
  FavoriteButton,
  CreditBar,
  Categories,
  Progress,
} from "./Components";
import { ArrowButton, LinkHandler } from "../Components";
import StarsTrail from "./Stars/stars-trail";

const CardTrail = ({ trail }) => {
  if (!trail) return null;
  return (
    <Container>
      <LinkHandler url={`/trilha-interna/${trail.id}`}>
        <HeaderCard image={trail.thumbnail ? trail.thumbnail.urlPublica : ""} />
      </LinkHandler>
      <BodyCard>
        <EvaluationBar>
          <Col width='45%'>
            <StarsTrail content={trail} />
          </Col>
          <Col width='45%'>
            <Duration duration={trail.workload} />
          </Col>
          <Col width='10%'>
            <FavoriteButton
              typeContent='trails'
              courseId={trail.id}
              evaluations={trail.evaluations}
            />
          </Col>
        </EvaluationBar>

        <CreditBar createdBy={trail.created_by} createdAt={trail.created_at} />

        <Categories
          categories={
            trail.categories &&
            trail.categories.length &&
            trail.categories.filter((category, index) => index <= 1)
          }
        />

        <LinkHandler
          url={`/trilha-interna/${trail.id}`}
          childrenPosition='left'
        >
          <Title>{trail.title || ""}</Title>
        </LinkHandler>

        <Progress progress={trail.progress} />

        <ArrowButton
          url={`/trilha-interna/${trail.id}`}
          target='_blank'
          title='SAIBA MAIS'
          position='left'
          color='#333'
        />
      </BodyCard>
    </Container>
  );
};

export default CardTrail;
