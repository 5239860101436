import React from "react";

import { Container, Tags, Tag } from "./styles";

const Categories = ({ categories }) => {
  if (!categories) return null;

  return (
    <Container>
      <Tags>
        {categories.map((tag) => (
          <Tag key={tag.id} category>
            {tag.title ? tag.title.split(" ")[0] : ""}
          </Tag>
        ))}
        <Tag>Carbel</Tag>
      </Tags>
    </Container>
  );
};

export default Categories;
