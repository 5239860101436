import React from "react";
import { Container, Image } from "./styles";
import placeholder from "../../../assets/images/placeholder.jpg";

const HeaderLibrary = ({ image }) => {
  return (
    <Container>
      <Image src={image || placeholder} alt='Imagem do curso' />
    </Container>
  );
};

export default HeaderLibrary;
