import styled from "styled-components";
import SearchIcon from "../../../assets/images/search-icon.svg";

export const Container = styled.div`
  margin: 0 10px;
  width: 200px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  padding: 8px 12px;
  width: 100%;
  border: 1px solid transparent;
  border-bottom-color: #3d3e3f;
  background-color: transparent;
  font-size: 13px;
  font-style: italic;
`;

export const Button = styled.button`
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-image: url(${SearchIcon});
  background-position: 30% 30%;
  background-size: 16px 20px;
  background-repeat: no-repeat;
  color: transparent;
  cursor: pointer;
`;
