import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  min-width: 0;
  @media screen and (max-width: 991px) {
    height: 350px;
  }
`;

export const Banner = styled.a`
  width: 100%;
  height: 450px;
  padding: 12% 45% 10px 16%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-image: linear-gradient(59deg, #211e2a, transparent 0%),
    url(${(props) => props.url});
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  text-decoration: none;

  @media screen and (max-width: 991px) {
    height: 350px;
  }
`;

export const Title = styled.h1`
  z-index: 99;
  padding: 0 0 10px;
  color: #fff;
  font-size: 65px;
  line-height: 65px;
  font-weight: 300;

  @media screen and (max-width: 630px) {
    font-size: 42px;
  }
`;
