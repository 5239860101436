import styled from "styled-components";

export const Container = styled.div`
  z-index: 9999;
  padding: 10px 0 0;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-bottom: 2px solid var(--secondary-color);

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const Navbar = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
