import React, { useEffect, useState } from "react";
import { getPagesBySlug } from "../../services/endpoints/cms/pages";
import { PageContainer } from "../../styles/GlobalStyles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
  UniCarbel,
  Spinner,
} from "../../components/Components";

const PageUniCarbel = ({ match }) => {
  const [slug] = useState(match.path.split("/")[1]);
  const [page, setPage] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadPage = async () => {
      try {
        const { data } = await getPagesBySlug(slug);
        setPage(data[0] || null);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    };

    setIsLoading(true);
    loadPage();
  }, [slug]);

  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <PageContainer>
        {isLoading ? <Spinner /> : <UniCarbel page={page} />}
      </PageContainer>
      <Footer />
    </>
  );
};

export default PageUniCarbel;
