/** @format */

import React, { useState, useEffect } from "react";
import { Container } from "./styles";
import { CardCourseInternal, Lessons, Scorm } from "../../Components";
import { ExamCongratsMessage } from "../../Components";
import CourseTestimonials from "../CourseTestimonials";
import LMSProgressProvider from "../../../contexts/LMSProgressProvider";

const CourseInternal = ({ content, courseProgress }) => {
  const [course, setCourse] = useState(content[0] || {});
  const [courseExam, setCourseExam] = useState();

  useEffect(() => {
    if (content && content[0]) {
      setCourse(content[0] || {});
      setCourseExam(
        (content[0].course_exams &&
          content[0].course_exams[0] &&
          content[0].course_exams[0].exam) ||
          null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(content[0].id)]);

  if (!content || !content[0]) return null;
  return (
    <LMSProgressProvider>
      <Container>
        <CardCourseInternal course={course} style={{ marginTop: "50px" }} />

        {course.scorm ? (
          course.file_scorm && (
            <Scorm content={course} contentType="course" courseId={course.id} />
          )
        ) : (
          <Lessons lessons={course.lessons} courseId={course.id} />
        )}
      </Container>

      <ExamCongratsMessage
        exam={courseExam}
        parentId={course.id}
        parentType="course"
        redirectUrl={window.location.pathname}
        courseExam={courseExam}
      />

      <CourseTestimonials course={course} />
    </LMSProgressProvider>
  );
};

export default CourseInternal;
