import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  font-size: 14px;
  line-height: 20px;
  color: #333;
  margin-bottom: 5px;
  font-weight: 700;
`;

export const Input = styled.input`
  margin: 0 0 10px;
  padding: 8px 12px;
  width: 100%;
  height: 38px;
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
`;

export const TextArea = styled.textarea`
  padding: 8px 12px 82px;
  margin: 0px -6px 10px 0px;
  width: 100%;
  height: 141px;
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  overflow: auto;
`;

export const Button = styled.button`
  margin: 0 0 0 auto;
  padding: 14px 24px;
  text-decoration: none;
  cursor: pointer;
  display: block;
  border: 1px solid #211e2a;
  border-radius: 6px;
  background-color: #fff;
  color: #333;
  font-size: 16px;
  text-align: left;

  &:hover {
    background-color: var(--secondary-color);
    border-color: transparent;
    color: #fff;
  }
`;
