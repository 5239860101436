import styled from "styled-components";

export const Container = styled.div``;

export const Tags = styled.div`
  padding-top: 0;
`;

export const Tag = styled.span`
  display: inline-block;
  padding: 4px 10px 3px;
  border-radius: 4px;
  margin: 0 5px;
  background-color: ${(props) => (props.category ? "#fc4c02" : "#00205b")};
  color: #fff;
`;
