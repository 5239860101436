import React, { useEffect, useState } from "react";
import { updateFavorites } from "../../../services/endpoints/general/evaluations";
import { getCurrentUser } from "../../../utils";
import { Container, Title, Button, Icon } from "./styles";

const FavoriteButton = ({
  title,
  courseId,
  evaluations,
  typeContent,
  isEditable,
  setReload,
}) => {
  const [favoriteCourse, setFavoriteCourse] = useState([]);

  useEffect(() => {
    const favorite =
      (evaluations && evaluations.find((item) => item.type === "FAVORITE")) ||
      0;

    setFavoriteCourse(favorite.userEvaluationValue || null);
  }, [evaluations]);

  const updateFavorite = async (event) => {
    event.preventDefault();

    const body = {
      user_iri: `/users/${getCurrentUser().id}`,
      content_iri: `/lms/${typeContent || "courses"}/${courseId}`,
      value: "1",
      type: "FAVORITE",
    };

    try {
      await updateFavorites(body);
      setFavoriteCourse((prev) => !prev);
      setReload && setReload(new Date().toLocaleString());
    } catch (err) {
      console.error(err);
      alert("Ocorreu algum erro ao adicionar aos favoritos.");
    }
  };

  const removeLastClassName = (event) => {
    event.preventDefault();
    const classList = event.target.className.split(" ");
    if (classList[classList.length - 1] === "fas") {
      classList.pop();
    }

    event.target.className = classList.join(" ");
  };

  // if (!courseId) return null;

  return (
    <Container onClick={(e) => updateFavorite(e)} isEditable={isEditable}>
      {title && <Title>{title}</Title>}
      <Button type='button'>
        <Icon
          className={`${favoriteCourse ? "fas" : "far"} fa-heart`}
          onMouseOver={(e) => isEditable && e.target.classList.add("fas")}
          onMouseLeave={(e) => isEditable && removeLastClassName(e)}
        />
      </Button>
    </Container>
  );
};

export default FavoriteButton;
