import React, { useState } from "react";
import { Container, Icon, NavLink } from "./styles";
import { LinkHandler } from "../../../../Components";

const DropdownLink = ({ link = {} }) => {
  const [icon] = useState(link.icon || "");
  const [url] = useState(link.url || "");
  const [target] = useState(link.target || "");
  const [title] = useState(link.title || "");

  return (
    <Container>
      <Icon src={icon} alt='' />
      <NavLink>
        <LinkHandler url={url} target={target} title={title} textColor='#fff' />
      </NavLink>
    </Container>
  );
};

export default DropdownLink;
