import React from "react";
import { Container, Title } from "./styles";
import SupportMaterial from "./SupportMaterial";

const SupportMaterials = ({ supportMaterials }) => {
  if (!supportMaterials || !supportMaterials.length) return null;
  return (
    <Container>
      <Title>Material complementar</Title>
      {supportMaterials.map((supportMaterial) => (
        <SupportMaterial
          supportMaterial={supportMaterial.support_material}
          key={`supportMaterial-${supportMaterial.id}`}
        />
      ))}
    </Container>
  );
};

export default SupportMaterials;
