import React, { useState } from "react";
import {
  Container,
  Header,
  Options,
  Option,
  Icon,
  Content,
  ExpandButtonContainer,
  ExpandButton,
  ExpandButtonTitle,
  ExpandButtonIcon,
} from "./styles";
import About from "./About";
import NextVideos from "./NextVideos";
import { WatchedButton } from "../../../Components";

const Sections = ({
  lesson,
  currentVideo,
  setCurrentVideo,
  isLessonContentExpanded,
  setIsLessonContentExpanded,
}) => {
  const OPTIONS = {
    ABOUT_LESSON: "aboutLesson",
    NEXT_VIDEOS: "nextVideos",
  };
  const [isActive, setIsActive] = useState(OPTIONS.ABOUT_LESSON);

  if (!lesson) return null;
  return (
    <Container>
      <Header>
        <Options>
          <Option
            isActive={isActive === OPTIONS.ABOUT_LESSON}
            onClick={(e) => setIsActive(OPTIONS.ABOUT_LESSON)}
          >
            Sobre a aula
          </Option>

          {lesson.lesson_contents.length > 1 && (
            <Option
              isActive={isActive === OPTIONS.NEXT_VIDEOS}
              onClick={(e) => setIsActive(OPTIONS.NEXT_VIDEOS)}
            >
              Próximas aulas deste curso
              <Icon className='fas fa-chevron-right' />
            </Option>
          )}

          {lesson.lesson_contents[currentVideo] &&
            lesson.lesson_contents[currentVideo].type === "FILE" && (
              <WatchedButton
                lessonContent={lesson.lesson_contents[currentVideo]}
                isEditable={true}
              />
            )}
        </Options>

        {lesson.lesson_contents[currentVideo].type === "FILE_SCORM" && (
          <ExpandButtonContainer
            isLessonContentExpanded={isLessonContentExpanded}
            onClick={(e) => {
              setIsLessonContentExpanded(
                (isLessonContentExpanded) => !isLessonContentExpanded
              );
            }}
          >
            <ExpandButton>
              <ExpandButtonIcon
                className={
                  isLessonContentExpanded ? "fas fa-compress" : "fas fa-expand"
                }
              />
            </ExpandButton>
            <ExpandButtonTitle>
              {isLessonContentExpanded ? "Retrair" : "Expandir"}
            </ExpandButtonTitle>
          </ExpandButtonContainer>
        )}
      </Header>

      <Content>
        {isActive === OPTIONS.ABOUT_LESSON && <About lesson={lesson} />}
        {isActive === OPTIONS.NEXT_VIDEOS && (
          <NextVideos
            nextVideos={lesson.lesson_contents}
            currentVideo={currentVideo}
            setCurrentVideo={setCurrentVideo}
          />
        )}
      </Content>
    </Container>
  );
};

export default Sections;
