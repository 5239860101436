import React, { useCallback, useEffect, useState } from "react";
import {
  getCourses,
  getCoursesTotal,
} from "../../services/endpoints/lms/courses";
import { getCategoriesBySlug } from "../../services/endpoints/lms/categories";
import { passToSnakeCase } from "../../utils/manipulateString";
import { Container, PageOptions } from "./styles";
import { PageContainer, PageTitle } from "../../styles/GlobalStyles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
  CoursesList,
  Pagination,
  Spinner,
} from "../../components/Components";
import CoursesSearch from "./CoursesSearch";

const PageCourses = ({ match }) => {
  const [categorySlug] = useState(match.params.slug);
  const [category, setCategory] = useState({});
  const [courses, setCourses] = useState([]);
  const [coursesTotal, setCoursesTotal] = useState(0);
  const [paginationDefault] = useState({
    perPage: 9,
    page: 0,
  });
  const [params, setParams] = useState({
    ...paginationDefault,
  });
  const [isLoading, setIsLoading] = useState(false);

  const fetchCategory = useCallback(async () => {
    try {
      const slug = passToSnakeCase(categorySlug);

      const { data } = await getCategoriesBySlug(slug);

      setCategory((data && data[0]) || {});
      setParams((params) => ({
        ...params,
        category_id: data && data[0] && data[0].id,
      }));
    } catch (err) {
      console.error(err);
    }
  }, [categorySlug]);

  const fetchCourses = useCallback(async () => {
    try {
      const { data } = await getCourses(params);
      setCourses(data || []);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert("Ocorreu algum erro ao buscar os cursos.");
      console.error(err);
    }
  }, [params]);

  const fetchCoursesTotal = useCallback(async () => {
    try {
      const { data } = await getCoursesTotal(params);
      setCoursesTotal(data || 0);
    } catch (err) {
      console.error(err);
    }
  }, [params]);

  useEffect(() => {
    if (categorySlug) {
      fetchCategory();
    }
  }, [categorySlug, fetchCategory]);

  useEffect(() => {
    setIsLoading(true);
    if (categorySlug) {
      if (params.category_id) {
        fetchCourses();
        fetchCoursesTotal();
      }
    } else {
      fetchCourses();
      fetchCoursesTotal();
    }
  }, [params, categorySlug, fetchCourses, fetchCoursesTotal]);

  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <PageContainer>
        <Container>
          <PageTitle>
            {categorySlug ? category.title || "" : "Cursos"}
          </PageTitle>

          <PageOptions>
            <CoursesSearch
              setParams={setParams}
              paginationDefault={paginationDefault}
              isLoading={isLoading}
            />
          </PageOptions>

          {!isLoading ? <CoursesList content={courses} /> : <Spinner />}

          <Pagination
            setParams={setParams}
            paginationDefault={paginationDefault}
            totalItems={coursesTotal}
          />
        </Container>
      </PageContainer>
      <Footer />
    </>
  );
};

export default PageCourses;
