/** @format */

import React, { useEffect } from "react";
import { Col } from "reactstrap";
import { BodyCard, EvaluationBar, Title, Description } from "./styles";
import { GlobalContainer } from "../../styles/GlobalStyles";
import {
  Stars,
  FavoriteButton,
  CreditBar,
  Categories,
  Progress,
} from "./Components";
import { LinkHandler } from "../Components";
import { useProgressContext } from "../../contexts/LMSProgressProvider";

const CardCourseInternal = ({ course }) => {
  const { updatedProgress, setUpdatedProgressParams } = useProgressContext();

  useEffect(() => {
    if (course.id) setUpdatedProgressParams({ id: course.id });
  }, [course.id, setUpdatedProgressParams]);

  if (!course) return null;
  return (
    <GlobalContainer noTransform={true} style={{ marginTop: "30px" }}>
      <BodyCard style={{ paddingTop: "15px" }}>
        <Categories categories={course.categories} />

        <LinkHandler
          url={`/curso-interno/${course.slug}`}
          childrenPosition="left"
        >
          <Title>{course.title || ""}</Title>
        </LinkHandler>
        <Description dangerouslySetInnerHTML={{ __html: course.summary }} />

        <CreditBar
          createdBy={course.created_by}
          createdAt={course.created_at}
        />

        <EvaluationBar>
          <Col xs={12} lg={4}>
            <Stars
              content={course}
              contentIri={`/lms/courses/${course.id}`}
              title="Avalie este curso"
            />
          </Col>
          <Col xs={12} lg={4}>
            <Progress progress={updatedProgress.progress} />
          </Col>
          <Col xs={12} lg={3}>
            <FavoriteButton
              evaluations={course.evaluations}
              courseId={course.id}
              title="Adicionar aos favoritos"
              isEditable={true}
            />
          </Col>
        </EvaluationBar>
      </BodyCard>
    </GlobalContainer>
  );
};

export default CardCourseInternal;
