import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Options = styled.div`
  padding: 0 20% 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width: 975px) {
    padding: 0;
  }
`;
export const Option = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? "var(--secondary-color)" : "inherit")};
  border-bottom: ${({ isActive }) =>
    isActive ? "2px solid var(--secondary-color)" : "none"};
  &:hover {
    border-bottom: 2px solid var(--secondary-color);
  }

  @media screen and (max-width: 575px) {
    font-size: 11px;
  }
`;
export const Icon = styled.i`
  padding: 0 10px;
  font-size: 13px;
`;

export const ExpandButtonContainer = styled.div`
  margin: 0 0 0 auto;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const ExpandButton = styled.button`
  width: 50px;
  height: 50px;
  // border-radius: 50%;
  // background-color: #fc4c02;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const ExpandButtonTitle = styled.p`
  margin: 0 0 0 5px;
`;

export const ExpandButtonIcon = styled.i`
  font-size: 25px;
  color: #00205b;
`;

export const Content = styled.div`
  padding: 15px;
  width: 100%;
`;
