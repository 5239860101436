import React from "react";
import { Container, Col, Row } from "reactstrap";
import { PageTitle } from "../../../styles/GlobalStyles";
import { CardCourse, ArrowButton } from "../../Components";

const CoursesMostWatched = ({ content, hasTitle = true }) => {
  if (!content || !content.length) return null;
  return (
    <Container>
      {hasTitle && (
        <PageTitle>
          Mais
          <br /> assistidos
        </PageTitle>
      )}
      <Row>
        {content.map((course) => (
          <Col xs='12' lg='3' key={course.id}>
            <CardCourse course={course} starSize='13px' />
          </Col>
        ))}
      </Row>

      {/* <ArrowButton
        url='/perfil'
        title='MAIS CURSOS'
        position='right'
        color='#333'
      /> */}
    </Container>
  );
};

export default CoursesMostWatched;
