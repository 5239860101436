import React from "react";
import { GlobalContainer } from "../../styles/GlobalStyles";
import { Container, Title, Icon } from "./styles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
} from "../../components/Components";

const PageForbidden = () => {
  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <GlobalContainer>
        <Container>
          <Title>Você não tem permissão para acessar essa página</Title>
          <Icon className="far fa-frown-open" />
        </Container>
      </GlobalContainer>
      <Footer />
    </>
  );
};

export default PageForbidden;
