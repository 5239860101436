import styled from "styled-components";

export const Container = styled.div`
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 700;
  font-size: 12px;
  width: ${({ isEditable }) => (isEditable ? "90px" : "auto")};
  background-color: ${({ isEditable }) =>
    isEditable ? "transparent" : "#00985f"};
  border: 1px solid
    ${({ isEditable }) => (isEditable ? "#00985f" : "transparent")};
  color: ${({ isEditable }) => (isEditable ? "#333" : "#fff")};
  cursor: ${({ isWatched }) => (isWatched ? "default" : "pointer")};
`;

export const Icon = styled.i`
  color: #26c740;
  font-size: 16px;
`;
