/** @format */

import React, { useState, createContext, useContext, useEffect } from "react";
import { getCoursesById } from "../../services/endpoints/lms/courses";

const LMSContextProgress = createContext();

export const useProgressContext = () => useContext(LMSContextProgress);

const LMSProgressProvider = ({ children }) => {
  const [params, setUpdatedProgressParams] = useState(false);
  const [updatedProgress, setUpdatedProgress] = useState({
    progress: 0,
    status: null,
  });

  const updateCourseStatus = async ({ id }) => {
    try {
      const { data } = await getCoursesById(id);
      if (data) {
        setUpdatedProgress({
          progress: data.progress,
          status: (data.course_stats && data.course_stats[0]) || null,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (params.id) updateCourseStatus(params);
  }, [params]);

  return (
    <LMSContextProgress.Provider
      value={{ updatedProgress, setUpdatedProgressParams }}
    >
      {children}
    </LMSContextProgress.Provider>
  );
};

export default LMSProgressProvider;
