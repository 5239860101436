import React, { useState, useEffect } from "react";
import { Container as BootstrapContainer, Row, Col } from "reactstrap";
import { Container, centerElements } from "./styles";
import Logo from "./Logo";
import SearchInput from "./SearchInput";
import Menu from "./Menu";
import MenuMobile from "./MenuMobile";

const NavbarPrimary = () => {
  const [isPositionFixed, setIsPositionFixed] = useState(false);

  const handleScrollChange = (e) => {
    if (window.scrollY > 80) {
      setIsPositionFixed(true);
    } else {
      setIsPositionFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleScrollChange(e));
    return () =>
      window.addEventListener("scroll", (e) => handleScrollChange(e));
  }, []);

  return (
    <Container isPositionFixed={isPositionFixed}>
      <BootstrapContainer>
        <Row>
          <Col xs={4} lg={3} style={centerElements}>
            <Logo />
          </Col>
          <Col xs={6} lg={5} style={centerElements}>
            <SearchInput />
          </Col>
          <Col xs={2} lg={4} style={centerElements}>
            <Menu />
            <MenuMobile />
          </Col>
        </Row>
      </BootstrapContainer>
    </Container>
  );
};

export default NavbarPrimary;
