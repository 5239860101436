import styled from "styled-components";

export const Container = styled.div`
  margin: 40px 0;
  width: 100%;
  min-height: 70vh;
`;

export const CourseNotFound = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  margin: 10px;
`;

export const Icon = styled.i`
  padding: 5px 0 0;
  font-size: 30px;
`;
