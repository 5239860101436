import React, { useCallback, useEffect, useState } from "react";
import {
  getLives,
  getLivesTotal,
} from "../../services/endpoints/general/lives";
import { Container } from "./styles";
import { PageContainer, PageTitle } from "../../styles/GlobalStyles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
  LivesList,
  Pagination,
  Spinner,
} from "../../components/Components";

const PageLives = () => {
  const [lives, setLives] = useState([]);
  const [livesTotal, setLivesTotal] = useState(0);
  const [paginationDefault] = useState({
    perPage: 9,
    page: 0,
  });
  const [params, setParams] = useState({
    ...paginationDefault,
  });
  const [isLoading, setIsLoading] = useState(false);

  const fetchLives = useCallback(async () => {
    try {
      const { data } = await getLives(params);
      setLives(data || []);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [params, setIsLoading]);

  const fetchLivesTotal = useCallback(async () => {
    try {
      const { data } = await getLivesTotal(params);
      setLivesTotal(data || 0);
    } catch (err) {
      console.error(err);
    }
  }, [params]);

  useEffect(() => {
    setIsLoading(true);
    fetchLives();
    fetchLivesTotal();
  }, [params, fetchLives, fetchLivesTotal]);

  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <PageContainer>
        <Container>
          <PageTitle>Lives</PageTitle>

          {!isLoading ? <LivesList content={lives} /> : <Spinner />}

          <Pagination
            setParams={setParams}
            paginationDefault={paginationDefault}
            totalItems={livesTotal}
          />
        </Container>
      </PageContainer>
      <Footer />
    </>
  );
};

export default PageLives;
