import styled from "styled-components";

export const Container = styled.a`
  padding: 10px;
  text-decoration: none;
  width: 100%;
  max-width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background-image: linear-gradient(
      14deg,
      rgba(51, 51, 51, 0.94),
      transparent 0%
    ),
    url(${({ backgroundImage }) => backgroundImage});
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
`;

export const Title = styled.h1`
  margin: 10px 0 20px;
  width: 80%;
  color: #fff;
  font-size: 28px;
  line-height: 34px;
  font-weight: 300;
`;
