/** @format */

import React, { useEffect } from "react";
import { useProgressContext } from "../../../contexts/LMSProgressProvider";
import CourseMessage from "./CourseMessage";
import LessonMessage from "./LessonMessage";
import { Container } from "./styles";

const ExamCongratsMessage = ({
  exam,
  parentId,
  parentType,
  // parentStatus,
  redirectUrl,
  courseExam,
}) => {
  const { updatedProgress, setUpdatedProgressParams } = useProgressContext();

  useEffect(() => {
    setUpdatedProgressParams({ id: parentId });
  }, [parentId, setUpdatedProgressParams]);

  if (!exam || !parentId || !parentType || !updatedProgress.status) {
    return <></>;
  }
  return updatedProgress.status &&
    updatedProgress.status.course_status === "CONCLUIDO" &&
    courseExam ? (
    <Container>
      {parentType === "course" ? (
        <CourseMessage
          exam={exam}
          parentId={parentId}
          parentType={parentType}
          parentStatus={updatedProgress.status}
          redirectUrl={redirectUrl}
        />
      ) : (
        <LessonMessage
          exam={exam}
          parentId={parentId}
          parentType={parentType}
          parentStatus={updatedProgress.status}
          redirectUrl={redirectUrl}
        />
      )}
    </Container>
  ) : (
    <></>
  );
};

export default ExamCongratsMessage;
