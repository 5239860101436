import styled from "styled-components";
import SearchIcon from "../../../assets/images/search-icon.svg";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  padding: 8px 12px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 200px;
  background-color: transparent;
  box-shadow: inset 1px 1px 3px 0 rgba(79, 82, 88, 0.34);
  font-size: 13px;
`;

export const Button = styled.button`
  width: 20px;
  height: 20px;
  position: absolute;
  left: auto;
  right: 10px;
  background-color: transparent;
  background-image: url(${SearchIcon});
  background-position: 30% 30%;
  background-size: 16px 20px;
  background-repeat: no-repeat;
  color: transparent;
  cursor: pointer;
`;
