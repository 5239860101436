import React from "react";
import { Container } from "./styles";
import { Col, Row } from "reactstrap";
import { CardCertificate } from "../Components";

const Certificates = ({ content }) => {
  if (!content || !content.length) return null;
  return (
    <Container>
      <Row>
        {content.map((certificate) => (
          <Col xs='12' lg='4' key={`certificate-${certificate.id}`}>
            <CardCertificate certificate={certificate} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Certificates;
