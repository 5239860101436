import api from "../../../api";

export const getSupportMaterials = (params) =>
  api.get(`/lms/support_materials`, {
    params: {
      ...params,
    },
  });

export const getSupportMaterialsTotal = (params) =>
  api.get(`/lms/support_materials/count_by`, {
    params: {
      ...params,
    },
  });

export const getSupportMaterialsBySlug = (slug, params) =>
  api.get(`/lms/support_materials/internal_library/`, {
    params: {
      slug,
      ...params,
    },
  });

export const getSupportMaterialsByKeyword = (keyword) => {
  return api.get(`/lms/support_materials?support_materials_search=${keyword}`);
};
