import React from "react";
import { TestimonialsItemContent, TestimonialsItemHead, TestimonialsItemStyled } from "./styles";
import NoProfilePic from "../../../assets/images/no-profile-picture-icon.jpg";

const TestimonialsItem = ({ content }) => {
  const avatar = content?.user?.profile?.avatar?.file?.urlPublica || NoProfilePic

  return (
    <TestimonialsItemStyled>
      <TestimonialsItemHead>
        <img src={avatar} alt="Avatar"/>
        <h3>{content && content.user && content.user.name}</h3>
      </TestimonialsItemHead>
      {content && <TestimonialsItemContent dangerouslySetInnerHTML={{ __html: content.description }} />}
    </TestimonialsItemStyled>
  );
};

export default TestimonialsItem;
