import React, { useState, useEffect, useCallback } from "react";
import { useCourseStatusContext } from "../../../contexts/CourseStatusProvider";
import {
  getWatched,
  updateWatcheds,
} from "../../../services/endpoints/general/watcheds";
import { getCurrentUser } from "../../../utils";
import { Container, Icon } from "./styles";

const WatchedButton = ({ lessonContent, isEditable }) => {
  const [isWatched, setIsWatched] = useState(false);
  const { updateCourseStatus } = useCourseStatusContext();

  const isLessonWatched = useCallback(async () => {
    try {
      const { data } = await getWatched(
        `/lms/lesson_contents/${lessonContent.id}`
      );
      return data && data[0] && data[0].watched;
    } catch (err) {
      console.error(err);
    }
  }, [lessonContent]);

  useEffect(() => {
    const verifyLessonWatched = async () => {
      setIsWatched(await isLessonWatched());
    };

    verifyLessonWatched();
  }, [isLessonWatched]);

  const updateLessonStats = async (event) => {
    event.preventDefault();

    const lessonContentId = lessonContent.id || null;
    if (!lessonContentId) return;

    const lessonCompleted = {
      user_iri: `users/${getCurrentUser().id}`,
      progress: 100,
      content_iri: `/lms/lesson_contents/${lessonContentId}`,
      watched: true,
    };

    const lessonNotCompleted = {
      user_iri: `users/${getCurrentUser().id}`,
      progress: 0,
      content_iri: `/lms/lesson_contents/${lessonContentId}`,
      watched: false,
    };

    try {
      if (!(await isLessonWatched())) {
        await updateWatcheds(lessonNotCompleted);
        await updateWatcheds(lessonCompleted);
        setIsWatched(true);
        updateCourseStatus();
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (!lessonContent) return null;
  return (
    <Container
      isEditable={isEditable}
      isWatched={isWatched}
      onClick={(e) => isEditable && updateLessonStats(e)}
    >
      VISTO
      {isEditable && (
        <Icon className={`${isWatched ? "fas" : "far"} fa-check-square`} />
      )}
    </Container>
  );
};

export default WatchedButton;
