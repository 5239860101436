/** @format */

import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import { Lessons, CardTrailInternal, Scorm } from "../../Components";
import TrailInternalMeta from "../TrailInternalMeta";

const TrailInternal = ({ content }) => {
  const [trail, setTrail] = useState({});
  const [actualCoursePosition, setActualCoursePosition] = useState(0);
  const [actualCourse, setActualCourse] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTrail(content && content[0]);
  }, [content]);

  useEffect(() => {
    setIsVisible(false);
    setActualCourse(
      (trail &&
        trail.trails_courses?.filter((item) => item.course) &&
        trail.trails_courses?.filter((item) => item.course)[
          actualCoursePosition
        ] &&
        trail.trails_courses?.filter((item) => item.course)[
          actualCoursePosition
        ].course) ||
        {}
    );
  }, [trail, actualCoursePosition]);

  if (!content || !content[0]) return null;
  return (
    <Container>
      <TrailInternalMeta
        content={content}
        setActualCoursePosition={setActualCoursePosition}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />

      {actualCourse && <CardTrailInternal course={actualCourse} />}

      {actualCourse &&
        (actualCourse.scorm ? (
          <Scorm
            content={actualCourse}
            contentType="course"
            courseId={actualCourse.id}
            oldProgress={actualCourse.progress}
          />
        ) : (
          <Lessons courseId={actualCourse.id} lessons={actualCourse.lessons} />
        ))}
    </Container>
  );
};

export default TrailInternal;
