import React, { useState } from "react";
import { Container, Button, Icon, Dropdown, DropdownLink } from "./styles";

const TrailsCategories = ({
  categories,
  setParams,
  paginationDefault,
  isLoading,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState("Todas as categorias");

  const handleCategoryClick = async (event, category) => {
    event.preventDefault();

    if (isLoading) return;

    if (category) {
      setSelected(category.title);
      setParams((params) => ({
        ...params,
        ...paginationDefault,
        category_id: `${category.id}`,
      }));
    } else {
      setSelected("Todas as categorias");
      setParams((params) => ({
        ...params,
        ...paginationDefault,
        category_id: undefined,
      }));
    }
  };

  if (!categories || !categories.length) return null;
  return (
    <Container>
      <Button onClick={(e) => setIsVisible((prev) => !prev)}>
        {selected}
        <Icon className='fas fa-chevron-down' />
      </Button>
      {isVisible && (
        <Dropdown>
          <DropdownLink onClick={(e) => handleCategoryClick(e)}>
            Todas as categorias
          </DropdownLink>

          {categories.map((category) => (
            <DropdownLink
              onClick={(e) => handleCategoryClick(e, category)}
              key={`lib-category-${category.id}`}
            >
              {category.title}
            </DropdownLink>
          ))}
        </Dropdown>
      )}
    </Container>
  );
};

export default TrailsCategories;
