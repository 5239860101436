import React from "react";
import { Col, Row } from "reactstrap";
import { Container } from "./styles";
import { CardCourse } from "../../Components";

const CoursesList = ({ content }) => {
  if (!content || !content.length) return null;
  return (
    <Container>
      <Row>
        {content.map((course) => (
          <Col xs='12' lg='4' key={course.id}>
            <CardCourse course={course} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CoursesList;
