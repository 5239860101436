import React, { useState } from "react";
import { postComment } from "../../../../../services/endpoints/general/comments";
import {
  Container,
  CreateNoteNowButton,
  CreateNoteForm,
  CreateNoteTitle,
  CreateNoteDescription,
  CreateNoteButton,
} from "./styles";

const CreateNote = ({ lessonId, userId, setReload }) => {
  const [isCreateNoteVisible, setIsCreateNoteVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleCreateNote = async (e) => {
    e.preventDefault();
    const body = {
      user_iri: `/users/${userId}`,
      parent_iri: `/lms/lessons/${lessonId}`,
      title,
      description,
    };
    try {
      await postComment(body);
      setTitle("");
      setDescription("");
      setReload(new Date().toLocaleString());
    } catch (err) {
      console.error(err);
      alert("Ocorreu um erro ao criar a anotação.");
    }
  };

  return (
    <Container>
      <CreateNoteNowButton
        onClick={(e) => setIsCreateNoteVisible((prev) => !prev)}
      >
        Criar uma nova anotação agora
      </CreateNoteNowButton>
      <CreateNoteForm
        isVisible={isCreateNoteVisible}
        onSubmit={(e) => handleCreateNote(e)}
      >
        <CreateNoteTitle
          placeholder='Título'
          required
          onChange={(e) => setTitle(e.target.value)}
        />
        <CreateNoteDescription
          placeholder='Comece aqui'
          required
          onChange={(e) => setDescription(e.target.value)}
        />
        <CreateNoteButton type='submit'>Criar nota</CreateNoteButton>
      </CreateNoteForm>
    </Container>
  );
};

export default CreateNote;
