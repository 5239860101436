import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ showContent }) =>
    showContent ? "rgb(0, 44, 244)" : "var(--secondary-color)"};
  cursor: pointer;

  & > * {
    padding: 0 5px;
  }

  @media screen and (max-width: 575px) {
    font-size: 12px;
  }
`;

export const Icon = styled.i`
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  transform: rotate(${({ showContent }) => (showContent ? "-180deg" : "0deg")});
  transition: all 0.4s ease;
`;

export const Text = styled.p``;

export const Bold = styled.strong`
  font-weight: bold;
`;

export const Span = styled.span``;
