import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & * {
    cursor: ${({ hasTitle }) => (hasTitle ? "pointer" : "pointer")};
  }
`;
export const Title = styled.p`
  padding: 0 5px;
`;
export const Button = styled.button`
  background-color: transparent;
`;
export const Icon = styled.i`
  font-size: 18px;
  color: var(--primary-color);
`;
