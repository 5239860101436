import React from "react";
import { Container, Col, Row } from "reactstrap";
import { FooterStyled, LogoFooter, Title, SocialFooter, Icon } from "./styled";
import { LinkHandler } from "../Components";
import logoFooter from "../../assets/images/logo-footer.png";

const Footer = () => {
  return (
    <FooterStyled>
      <Container className='py-3'>
        <Row>
          <Col xs={12} lg={6}>
            <LogoFooter src={logoFooter} alt='Logo footer' />
            <p>
              Copyright © 2020 - Todos os direitos reservados - Carbel Academy
            </p>
          </Col>
          <Col
            xs={12}
            lg={6}
            className='d-flex flex-column justify-content-center align-items-center text-center'
          >
            <Title>Siga-nos nas redes sociais</Title>
            <SocialFooter>
              <LinkHandler
                url='https://www.instagram.com/carbel_academy'
                target='_blank'
              >
                <Icon icon='instagram' className='fab fa-instagram' />
              </LinkHandler>
              <LinkHandler
                url='https://www.linkedin.com/company/carbel-auto-group'
                target='_blank'
              >
                <Icon icon='linkedin' className='fab fa-linkedin-in' />
              </LinkHandler>
            </SocialFooter>
          </Col>
        </Row>
      </Container>
    </FooterStyled>
  );
};

export default Footer;
