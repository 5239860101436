import React, { useState, createContext, useContext } from "react";

const ExamContext = createContext();

export const useExamContext = () => useContext(ExamContext);

const ExamProvider = ({ children }) => {
  const [examId, setExamId] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [parentType, setParentType] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  return (
    <ExamContext.Provider
      value={{
        examId,
        setExamId,
        parentId,
        setParentId,
        parentType,
        setParentType,
        redirectUrl,
        setRedirectUrl,
      }}
    >
      {children}
    </ExamContext.Provider>
  );
};

export default ExamProvider;
