import React, { useEffect, useState } from "react";
import { GlobalContainer } from "../../styles/GlobalStyles";
import { Container, Navbar } from "./styles";
import NavbarLink from "./NavbarLink";
import NavbarDropdown from "./NavbarDropdown";
import { getMenusById } from "../../services/endpoints/cms/menus";

const MENU_ID = 6;

const NavbarSecondary = () => {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const { data } = await getMenusById(MENU_ID);
        setMenu((data && data[0] && data[0].menu_items) || []);
      } catch (err) {
        console.error(err);
      }
    };

    loadData();
  }, []);

  return (
    <Container>
      <GlobalContainer>
        <Navbar>
          {menu.map((menuItem) =>
            menuItem.menu_items && menuItem.menu_items.length ? (
              <NavbarDropdown
                dropdown={menuItem}
                key={`menuItem-${menuItem.id}`}
              />
            ) : (
              <NavbarLink link={menuItem} key={`menuItem-${menuItem.id}`} />
            )
          )}
        </Navbar>
      </GlobalContainer>
    </Container>
  );
};

export default NavbarSecondary;
