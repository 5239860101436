import api from "../../../api";

export const getPerformedExams = (params) =>
  api.get(`/lms/exam_applications`, {
    params: {
      ...params,
    },
  });

export const getExams = (id, params) =>
  api.get(`/lms/exams/${id}`, {
    params: {
      ...params,
    },
  });

export const postExams = (id, object_id, answers) =>
  api.post(`/lms/exams/${id}/submission?${object_id}`, answers);

export const getExamApproval = (userId, contentId) =>
  api.get(
    `/lms/exam_applications?user_iri=/users/${userId}&wherein${contentId}&order[grade_obtained]=desc`
  );
