import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Icon = styled.img`
  width: 28px;
  max-width: 100%;
  max-height: 100%;
  background-color: #fff;
`;

export const NavLink = styled.div`
  margin: 0 10px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    a {
      color: var(--secondary-color);
    }
  }

  a {
    text-decoration: none;
    font-size: 15px;
    color: #fff;
  }
`;
