import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, Form, Formik } from "formik";
import { mapInitialValues } from "./util/mapInitialValues";
import { mapValidations } from "./util/mapValidations";
import MakeField from "./MakeField";

const GenerateForm = ({ groupFields, onSubmit, buttonText }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={mapInitialValues(groupFields)}
      validationSchema={mapValidations(groupFields)}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          {groupFields.map((group, index) => (
            <div
              key={`group-${index}`}
              className='group-field'
              id={`group-${index}`}
            >
              {group.name !== "" && (
                <label htmlFor={`group-${index}`}>{group.name}</label>
              )}

              {group.fields.map((field, idx) => (
                <div key={`field-${idx}`} className='element-field'>
                  {field.hasPlaceholder === false && (
                    <label className='label-title' htmlFor={field.name}>
                      {field.label}
                    </label>
                  )}
                  <MakeField {...field} />
                  <ErrorMessage component='span' name={field.name} />
                </div>
              ))}
            </div>
          ))}

          <div className='footer-buttons'>
            <button type='submit' className='btn btn-primary'>
              {isSubmitting ? "Salvando..." : buttonText || "Salvar"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

GenerateForm.propTypes = {
  groupFields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default GenerateForm;
