import api from "../../api";

export const getSettings = () => {
  return api.get("/settings");
};

export const getSambaSettings = async () => {
  const settings = await getSettings();
  const sambaId = "sambatech_player_key";
  const sambaSettings = settings.data.filter(
    (setting) => setting.id === sambaId
  );

  return sambaSettings[0];
};

export const getForumUrl = async () => {
  const settings = await getSettings();
  const flarumId = "flarum_url";

  const sambaSettings = settings.data.filter(
    (setting) => setting.id === flarumId
  );

  return sambaSettings[0];
};

export const getFlarumCookie = async () => {
  return api.get("/flarum_sso/flarum/login");
};
