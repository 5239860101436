import React, { useState, useEffect } from "react";
import { Container, Title, Icons, Icon } from "./styles";
import { updateRating } from "../../../services/endpoints/general/evaluations";
import { getCurrentUser } from "../../../utils";

const Stars = ({ content, contentIri, title, starSize }) => {
  const [average, setAverage] = useState(0);
  const [tempAverage, setTempAverage] = useState(average);
  const [hasEditOption] = useState(!!title);
  const STARS = [1, 2, 3, 4, 5];

  useEffect(() => {
    if (content && content.evaluations && !!content.evaluations.length) {
      const rating = content.evaluations.find(
        (evaluation) => evaluation.type === "RATING"
      );

      if (rating) {
        setAverage(
          hasEditOption
            ? rating.userEvaluationValue || rating?.value || 0
            : Math.round(rating.average)
        );
      }
    } else {
      setAverage(hasEditOption ? 0 : 5);
    }
  }, [content, hasEditOption]);

  const handleClick = async (event, star) => {
    event.preventDefault();
    try {
      const body = {
        user_iri: `/users/${getCurrentUser().id}`,
        content_iri: contentIri,
        value: star,
        type: "RATING",
      };

      await updateRating(body);

      setAverage(star);
      setTempAverage(star);
    } catch (err) {
      console.error(err);
    }
  };

  const handleMouseOver = (event, star) => {
    event.preventDefault();
    setTempAverage(average);
    setAverage(star);
  };

  const handleMouseOut = (event) => {
    event.preventDefault();
    setAverage(tempAverage);
  };

  if (!content) return null;
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <Icons>
        {STARS.map((star) => (
          <Icon
            className={`${star <= average ? "fas" : "far"} fa-star`}
            onClick={(e) => hasEditOption && handleClick(e, star)}
            onMouseOver={(e) => hasEditOption && handleMouseOver(e, star)}
            onMouseOut={(e) => hasEditOption && handleMouseOut(e)}
            hasEditOption={hasEditOption}
            starSize={starSize}
            key={`star-${star}`}
          />
        ))}
      </Icons>
    </Container>
  );
};

export default Stars;
