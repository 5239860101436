import React from "react";
import axios from "axios";
import { getToken } from "../../services/auth";
import { Container, BodyCard, Title, Button, ButtonIcon } from "./styles";
import { HeaderCard, Categories, Progress } from "./Components";
import DownloadIcon from "../../assets/images/download-icon.svg";

const CardCertificate = ({ certificate }) => {
  if (!certificate) return null;

  const getCertificado = (e, courseId) => {
    e.preventDefault();
    axios({
      url: `${process.env.REACT_APP_API_URL}/lms/courses/${courseId}/certificate_issuance`,
      method: "GET",
      responseType: "arraybuffer", // important
      headers: { Authorization: getToken() },
    }).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(url, "_blank");
    });
  };

  return (
    <Container>
      <HeaderCard
        image={
          certificate.course.image ? certificate.course.image.urlPublica : ""
        }
      />
      <BodyCard>
        <Categories categories={certificate.categories} />

        <Title>{certificate.course.title || ""}</Title>
        <Progress progress={certificate.progress} />
        <Button onClick={(e) => getCertificado(e, certificate.course.id)}>
          BAIXAR CERTIFICADO
          <ButtonIcon url={DownloadIcon} />
        </Button>
      </BodyCard>
    </Container>
  );
};

export default CardCertificate;
