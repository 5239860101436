import api from "../../../api";

export const getTrails = (params = {}) => {
  return api.get("/lms/trails?active=true", {
    params: {
      ...params,
    },
  });
};

export const getTrailsTotal = (params = {}) => {
  return api.get("/lms/trails/count_by?active=true", {
    params: {
      ...params,
    },
  });
};

export const getTrailsById = (id, params = {}) => {
  return api.get("/lms/trails?active=true", {
    params: {
      id,
      ...params,
    },
  });
};

export const getTrailsByKeyword = (keyword) => {
  return api.get(`/lms/trails?trails_search=${keyword}&active=true`);
};

export const getTrailStatus = (trailId) => {
  return api.get(`lms/trail_stats?wherein[trail.id]=${trailId}`);
};
