import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 991px) {
    display: none;
  }
`;

export const Bars = styled.i`
  font-size: 16px;
  color: var(--primary-color);
  cursor: pointer;
`;

export const Menu = styled.div`
  padding: 10px;
  width: 300px;
  position: fixed;
  top: 80px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--primary-color);
  transform: ${({ isVisible }) =>
    isVisible ? "translateX(0%)" : "translateX(100%)"};
  transition: transform 0.4s ease;

  @media screen and (max-width: 575px) {
    width: 100%;
    left: 0;
    right: 0;
  }
`;
