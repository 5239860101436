import React, { useState, useEffect } from "react";
import { getCommentsByLessonIdAndUserId } from "../../../../services/endpoints/general/comments";
import { getCurrentUser } from "../../../../utils/";
import { Container } from "./styles";
import CreateNote from "./CreateNote";
import Note from "./Note";
import { Spinner } from "../../../Components";

const Notes = ({ lessonId }) => {
  const [userId] = useState(getCurrentUser().id);
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const loadNotes = async () => {
      try {
        const { data } = await getCommentsByLessonIdAndUserId(lessonId, userId);
        setNotes(data || []);
      } catch (err) {
        console.error(err);
      }

      setIsLoading(false);
    };

    setIsLoading(true);
    setTimeout(() => {
      loadNotes();
    }, 2000);
  }, [lessonId, userId, reload]);

  return (
    <Container>
      <CreateNote
        lessonId={lessonId}
        userId={userId}
        setReload={setReload}
        key={`createNote-${reload}`}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        notes.map((note) => (
          <Note
            note={note}
            lessonId={lessonId}
            userId={userId}
            setReload={setReload}
            key={`note-${note.id}`}
          />
        ))
      )}
    </Container>
  );
};

export default Notes;
