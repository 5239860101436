import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ position }) => {
    switch (position) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  }};
  cursor: pointer;
`;
export const Icon = styled.img`
  margin-left: 12px;
  width: 32px;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;

  transition: transform 0.25s linear;

  ${Container}:hover & {
    transform: translateX(5px);
  }
`;
