import React, { useState } from "react";
import { LinkHandler } from "../../../Components";
import { Container, Icon, NavLink } from "./styles";

const MenuLink = ({ link = {} }) => {
  const [icon] = useState(link.icon || "");
  const [url] = useState(link.url || "");
  const [target] = useState(link.target || "");
  const [title] = useState(link.title || "");

  if (!link) return null;

  return (
    <Container>
      <Icon src={icon} alt='' />
      <NavLink>
        <LinkHandler
          url={url}
          target={target}
          title={title}
          textColor='#FFFFFF'
        />
      </NavLink>
    </Container>
  );
};

export default MenuLink;
