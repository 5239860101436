import { getExamApproval } from "../services/endpoints/lms/exams";

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("carbel.user"));
};

export const isExternalUrl = (url) => {
  if (url) {
    return (
      url.includes("http:") || url.includes("https:") || url.includes("www.")
    );
  }

  return false;
};

export const updateBlockEndpoint = (blocks, blockName, newEndpoint) => {
  if (blocks && blockName) {
    return blocks.map((block) => {
      if (block.block.name === blockName) {
        block.block.endpoint = newEndpoint;
      }
      return block;
    });
  }
};

export const removeDuplicatesByProperty = (array, property) => {
  if (array && array.length && property) {
    const arrayStorage = {};

    array.forEach((item) => {
      if (!arrayStorage[item[property]]) {
        arrayStorage[item[property]] = item;
      }
    });

    const newArray = [];
    for (let item in arrayStorage) {
      newArray.push(arrayStorage[item]);
    }

    return newArray;
  }

  return array;
};

export const getCoursesCategoriesFromTrail = (trailCourses) => {
  let categories = [];
  if (trailCourses && trailCourses.length) {
    trailCourses.forEach(({ course }) => {
      if (course) {
        categories = [...categories, ...course.categories];
      }
    });
  }
  return removeDuplicatesByProperty(categories, "title");
};

export const hasMandatoryExam = (course) => {
  return (
    course &&
    course.course_exams &&
    course.course_exams[0] &&
    course.course_exams[0].exam &&
    course.course_exams[0].exam.mandatory_approval
  );
};

export const isApproved = async (contentId, contentType) => {
  try {
    const { data } = await getExamApproval(
      getCurrentUser().id,
      contentType === "course"
        ? `[course.id]=${contentId}`
        : `[lesson.id]=${contentId}`
    );
    return data && data[0] && data[0].approved;
  } catch (err) {
    console.error(err);
  }
};

export const isDateExpired = (date) => {
  if (date) {
    date = formatDate(date.replace(/\//g, "-"));
    let currentDate = formatDate(
      new Date().toLocaleString().replace(/\//g, "-")
    );

    date = new Date(date).getTime();
    currentDate = new Date(currentDate).getTime();

    return date < currentDate;
  }
  return true;
};

export const formatDate = (date) => {
  if (date) {
    const dateSplited = date.split(" ");
    const firstPart = dateSplited[0].split("-");
    [firstPart[0], firstPart[2]] = [firstPart[2], firstPart[0]];
    firstPart[1] = parseInt(firstPart[1]);

    date = `${firstPart.join("-")} ${dateSplited[1]}`;
  }

  return date;
};
