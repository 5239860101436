import styled from "styled-components";

export const Container = styled.div`
  margin: 0 10px 20px;
  padding: 24px 0 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.03);
  color: #333;
`;

export const Header = styled.div`
  width: 100%;
`;
export const Content = styled.div`
  margin: 10px 0 0;
  padding: ${({ showContent }) => (showContent ? "15px" : "0")};
  width: 100%;
  height: 100%;
  max-height: ${({ showContent }) => (showContent ? "100%" : "0")};
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
`;

export const LeftContainer = styled.div`
  width: 100%;
  max-width: ${({ isExpanded }) => (isExpanded ? "100%" : "70%")};
  transition: max-width 0.8s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 991px) {
    width: 100%;
    max-width: 100%;
  }
`;
export const RightContainer = styled.div`
  width: 100%;
  max-width: ${({ isExpanded }) => (isExpanded ? "100%" : "30%")};
  transition: max-width 0.8s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 991px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Video = styled.div`
  z-index: 1000;
  width: 100%;
  max-height: ${({ isExpanded }) => (isExpanded ? "auto" : "720px")};
`;
