import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  diplay: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Title = styled.h5`
  margin: 10px 0 20px;
  padding: 0 0 10px;
  color: #333;
  font-size: 30px;
  font-weight: 300;
  line-height: 20px;
`;
