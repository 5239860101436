import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
