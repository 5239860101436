import React from "react";
import { Container, Col, Row } from "reactstrap";
import { PageTitle } from "../../../styles/GlobalStyles";
import { CardCourse } from "../../Components";

const CoursesWatcheds = ({ content, hasTitle = true }) => {
  return (
    <Container>
      {hasTitle && <PageTitle>Assistidos</PageTitle>}
      <Row>
        {content.map((course) => (
          <Col xs='12' lg='4' key={course.id}>
            <CardCourse course={course} noProgress={true} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CoursesWatcheds;
