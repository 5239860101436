import React from "react";
import NextVideo from "./NextVideo";
import { Container } from "./styles";

const NextVideos = ({ nextVideos, currentVideo, setCurrentVideo }) => {
  if (!nextVideos || !nextVideos.length) return null;
  return (
    <Container>
      {nextVideos.map(
        (nextVideo, index) =>
          index !== currentVideo && (
            <NextVideo
              key={nextVideo.id}
              nextVideo={nextVideo}
              position={index}
              totalVideos={nextVideos.length}
              setCurrentVideo={setCurrentVideo}
            />
          )
      )}
    </Container>
  );
};

export default NextVideos;
