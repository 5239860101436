import React, { useEffect, useState } from "react";
import { stripTags } from "../../utils/manipulateString";
import background from "../../assets/images/carbe-home-image-background.png";
import { Container, BoxPhoto, TextBox } from "./styles";

const HomeImage = ({ content }) => {
  const [banner, setBanner] = useState({});

  useEffect(() => {
    setBanner((content && content.banners && content.banners[0]) || {});
  }, [content]);

  if (!content) return null;
  return (
    <Container url={background}>
      <BoxPhoto>
        <img
          src={(banner.file && banner.file.urlPublica) || ""}
          alt='Fotos Carbel'
        />
        <TextBox>
          <h2 dangerouslySetInnerHTML={{ __html: stripTags(banner.title) }} />
          <p
            dangerouslySetInnerHTML={{ __html: stripTags(banner.description) }}
          />
        </TextBox>
      </BoxPhoto>
    </Container>
  );
};

export default HomeImage;
