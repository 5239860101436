/** @format */

import React, { useCallback, useEffect, useState } from "react";
import {
  getCoursesBySlug,
  getCourseStatus,
} from "../../services/endpoints/lms/courses";
import { Container, ErrorMessageStyled } from "./styles";
import { PageContainer } from "../../styles/GlobalStyles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
  Spinner,
  CourseInternal,
} from "../../components/Components";
import { useCourseStatusContext } from "../../contexts/CourseStatusProvider";

const PageCourseInternal = ({ match }) => {
  const [slug] = useState(match.params.slug);
  const [course, setCourse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { courseStatusUpdated } = useCourseStatusContext();
  const [courseProgress, setCourseProgress] = useState(0);

  const fetchCourse = useCallback(async () => {
    try {
      const { data } = await getCoursesBySlug(slug);
      setCourse(data || []);
      setCourseProgress(data[0].progress);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [slug]);

  useEffect(() => {
    setIsLoading(true);
    fetchCourse();
  }, [fetchCourse]);

  useEffect(() => {
    const fetchCourseStatus = async () => {
      try {
        const { data } = await getCourseStatus(course[0].id);
        const tmpCourse = course;
        tmpCourse[0].course_stats = data || [];

        setCourse([...tmpCourse]);
      } catch (err) {
        console.error(err);
      }
    };

    if (course && course[0] && course[0].id) {
      fetchCourseStatus();
    }
    //eslint-disable-next-line
  }, [courseStatusUpdated]);

  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <PageContainer>
        <Container>
          {course.length ? (
            !isLoading ? (
              <CourseInternal
                content={course}
                courseProgress={courseProgress}
                slug={slug}
              />
            ) : (
              <Spinner />
            )
          ) : !isLoading ? (
            <ErrorMessageStyled>
              <i className="fas fa-exclamation-triangle" />
              <h1>Você não tem permissão pra acessar esse conteúdo.</h1>
            </ErrorMessageStyled>
          ) : (
            <Spinner />
          )}
        </Container>
      </PageContainer>
      <Footer />
    </>
  );
};

export default PageCourseInternal;
