import React from "react";
import { Container, Form, Input, Button } from "./styles";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const SearchInput = () => {
  const history = useHistory();
  const [searchedWord, setSearchedWord] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(`/search/${searchedWord}`);
  };

  return (
    <Container>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Input
          type='search'
          name='searchInput'
          maxLength='256'
          required
          onChange={(e) => setSearchedWord(e.target.value)}
        />
        <Button
          type='submit'
          style={{ right: `${searchedWord.length ? "35px" : "10px"}` }}
        />
      </Form>
    </Container>
  );
};

export default SearchInput;
