import styled from "styled-components";

export const Container = styled.div`
  margin: 15px 5px 45px;
  height: 100%;
  border-radius: 3px;
  &:hover {
    transform: scale(1.01, 1.01);
  }
`;

export const BodyCard = styled.div`
  padding: 0 12px 25px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  background-color: #f5f5f5;
`;

export const EvaluationBar = styled.div`
  padding: 15px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 575px) {
    align-items: flex-start;
    justify-content: space-around;
    & > * {
      margin: 5px 0;
    }
  }
`;

export const TitleInterna = styled.div`
  padding: 15px 0;
  font-size: 24px;
  color: #333;
  line-height: 28px;
  font-weight: 400;
  text-decoration: none;
`

export const Title = styled(TitleInterna)`
  padding: 15px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const Description = styled.div`
  font-size: 18px;
  color: #333;
  line-height: 28px;
  font-weight: 300;
  text-decoration: none;
  padding: 15px 0;
`;

export const Button = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #333;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
`;

export const ButtonIcon = styled.div`
  padding: 10px 10px 10px 32px;
  background-image: url(${({ url }) => url});
  background-position: 50% 50%;
  background-size: 20px;
  background-repeat: no-repeat;
`;
