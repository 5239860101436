import React from "react";
import { Container, Title, Image, Description } from "./styles";
import Placeholder from "../../assets/images/placeholder.jpg";

function UniCarbel({ page }) {
  if (!page) return null;
  return (
    <Container>
      <Title dangerouslySetInnerHTML={{ __html: page.title || "" }} />

      <Image
        src={(page.image && page.image.urlPublica) || Placeholder}
        alt='banner texto'
      />
      <Description dangerouslySetInnerHTML={{ __html: page.content }} />
    </Container>
  );
}

export default UniCarbel;
