import styled from "styled-components";

export const Container = styled.div`
  margin: 10px 0;
  padding: 15px;
  width: 100%;
  height: auto;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.03);
  border-style: solid solid none;
  border-color: rgba(0, 0, 0, 0.15);
  border-radius: 6px;

  overflow: hidden;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
  }
`;
export const Title = styled.p`
  margin: 10px 0;
  width: 45%;
  max-width: 45%;
  font-size: 16px;
  font-weight: 400;
  color: ${({ isVisible }) =>
    isVisible ? "var(--primary-color)" : "var(--secondary-color)"};
  cursor: pointer;
  overflow-wrap: break-word;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const TitleEdit = styled.textarea`
  margin: 10px 0;
  padding: 10px 5px;
  width: 50%;
  font-size: 16px;
  font-weight: 400;
  color: black;
  border-radius: 6px;
  background-color: #eaeaea;
  cursor: pointer;

  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  margin: 10px 10px;
  width: 115px;
  height: 43px;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: var(--secondary-color);
  border-radius: 6px;
  background-color: transparent;

  &:hover {
    background-color: var(--secondary-color);
    color: #fff;
    border-color: transparent;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;
export const Description = styled.p`
  margin: 10px 0;
  padding: ${({ isVisible }) => (isVisible ? "20px 5px" : "0")};
  width: 100%;
  height: 100%;
  max-height: ${({ isVisible }) => (isVisible ? "100%" : "0")};
  transform: ${({ isVisible }) => (isVisible ? "scaleY(1)" : "scaleY(0)")};
  transition: transform 0.3s ease;
  transform-origin: top;
  overflow: hidden;
  font-size: 17px;
  line-height: 28px;
  text-align: left;

  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const DescriptionEdit = styled.textarea`
  margin: 10px 0;
  padding: 20px 5px;
  width: 100%;
  height: 100%;
  font-size: 17px;
  line-height: 28px;
  text-align: left;
  border-radius: 6px;
  background-color: #eaeaea;

  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const EditOptions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonEdit = styled.button`
  margin: 10px 10px;
  width: 115px;
  height: 43px;
  width: 25%;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: var(--primary-color);
  border-radius: 6px;
  background-color: transparent;

  &:hover {
    background-color: var(--primary-color);
    color: #fff;
    border-color: transparent;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;
