import api from "../../../api";
import { getCurrentUser } from "../../../../utils";

export const getFieldValues = () => {
  return api.get("/general/field_values", {
    params: {
      user_iri: getCurrentUser().id,
    },
  });
};

export const getFieldValuesByName = (name) => {
  return api.get("/general/field_values", {
    params: {
      user_iri: getCurrentUser().id,
      "match[field.title]": name,
    },
  });
};

export const getFieldByName = (name) => {
  return api.get("/general/fields", {
    params: {
      title: name,
    },
  });
};

export const uploadAvatar = (id, data) => {
  return api.post(`/general/field_values/${id}/upload/file`, data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const setFieldsValues = (data) => {
  return api.post("/general/fieldValuesBatch", data);
};
