import React, { useEffect, useState } from "react";
import { Spinner } from "../components/Components";
import { getPagesBySlug } from "../services/endpoints/cms/pages";
import { PageContainer } from "../styles/GlobalStyles";
import { snakeToPascalCase } from "../utils/manipulateString";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
} from "../components/Components";
import { PageFactory, PageNotFound } from "./Pages";

const PageBase = ({ match }) => {
  const slug = match.params.slug ? match.params.slug.toLowerCase() : "home";
  const [page, setPage] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadPage = async () => {
      try {
        const { data } = await getPagesBySlug(slug);
        setPage(data[0] || null);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    };

    setIsLoading(true);
    loadPage();
  }, [slug]);

  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />

      {isLoading ? (
        <PageContainer>
          <Spinner />
        </PageContainer>
      ) : (
        <>
          {page ? (
            page.title && (
              <PageFactory
                pageName={snakeToPascalCase(page.title)}
                page={page}
              />
            )
          ) : (
            <PageNotFound />
          )}
        </>
      )}

      <Footer />
    </>
  );
};

export default PageBase;
