import React, { useState, useEffect } from "react";
import { Container } from "./styles";
import FirstPage from "./FirstPage";
import PrevPage from "./PrevPage";
import Pages from "./Pages";
import NextPage from "./NextPage";
import LastPage from "./LastPage";

const Pagination = ({ setParams, paginationDefault, totalItems }) => {
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const itemsPerPage = paginationDefault.itemsPerPage || 9;
    const numberOfPages =
      totalItems >= itemsPerPage ? Math.ceil(totalItems / itemsPerPage) : 1;

    setPages(createArrayOfGivenNumber(numberOfPages));
    setCurrentPage(paginationDefault.page || 0);
  }, [totalItems, paginationDefault]);

  useEffect(() => {
    setParams((params) => ({
      ...params,
      page: currentPage,
    }));
  }, [setParams, currentPage]);

  if (pages.length < 2) return null;
  return (
    <Container>
      <FirstPage setCurrentPage={setCurrentPage} />
      <PrevPage setCurrentPage={setCurrentPage} />

      <Pages
        pages={pages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <NextPage setCurrentPage={setCurrentPage} pagesTotal={pages.length - 1} />
      <LastPage setCurrentPage={setCurrentPage} pagesTotal={pages.length - 1} />
    </Container>
  );
};

export default Pagination;

const createArrayOfGivenNumber = (number) => {
  if (number) {
    const tmpPages = [];
    for (let i = 0; i < number; i++) {
      tmpPages.push(i);
    }
    return tmpPages;
  }
  return [];
};
