import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import { redirectToLogout } from "./services/auth";
import { getMe } from "./services/endpoints/users";
import { Spinner } from "./components/Components";
import { PageContainer } from "./styles/GlobalStyles";
import {
  PageBase,
  PageUniCarbel,
  PageCourses,
  PageCourseInternal,
  PageTrails,
  PageTrailInternal,
  PageProfile,
  PageCertificates,
  PageSearch,
  PageLibrary,
  PageLibraryInternal,
  PageLives,
  PageLiveInternal,
  PageExam,
  PageNotFound,
  PageForbidden,
} from "./pages/Pages";

const Routes = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const requireLogin = async () => {
      setIsLoading(true);

      try {
        const { data } = await getMe();
        localStorage.setItem(`carbel.user`, JSON.stringify(data));
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        redirectToLogout();
      }
    };

    requireLogin();
  }, []);

  return isLoading ? (
    <PageContainer>
      <Spinner />
    </PageContainer>
  ) : (
    <Router>
      <GuardProvider>
        <Switch>
          <GuardedRoute exact path="/" component={PageBase} />
          <GuardedRoute exact path="/403" component={PageForbidden} />
          <GuardedRoute exact path="/404" component={PageNotFound} />
          <GuardedRoute exact path="/uni-carbel" component={PageUniCarbel} />
          <GuardedRoute exact path="/cursos" component={PageCourses} />
          <GuardedRoute exact path="/cursos/:slug" component={PageCourses} />
          <GuardedRoute
            exact
            path="/curso-interno/:slug"
            component={PageCourseInternal}
          />
          <GuardedRoute exact path="/trilhas" component={PageTrails} />
          <GuardedRoute
            exact
            path="/trilha-interna/:slug"
            component={PageTrailInternal}
          />
          <GuardedRoute exact path="/biblioteca" component={PageLibrary} />
          <GuardedRoute
            exact
            path="/biblioteca-interna/:slug"
            component={PageLibraryInternal}
          />
          <GuardedRoute exact path="/listagem-live" component={PageLives} />
          <GuardedRoute
            exact
            path="/live-interna/:slug"
            component={PageLiveInternal}
          />
          <GuardedRoute
            exact
            path="/curso-interno/:slug/prova"
            component={PageExam}
          />
          <GuardedRoute exact path="/search/:keyword" component={PageSearch} />
          <GuardedRoute exact path="/perfil" component={PageProfile} />
          <GuardedRoute exact path="/certificados" component={PageCertificates} />
          <GuardedRoute exact path="/:slug" component={PageBase} />
          <GuardedRoute exact path="*" component={PageNotFound} />
        </Switch>
      </GuardProvider>
    </Router>
  );
};

export default Routes;
