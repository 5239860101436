import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 100%;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
`;
