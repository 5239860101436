import React from "react";
import { Container, Title } from "./styles";
import NoBanner from "../../../assets/images/placeholder.jpg";
import { ArrowButton } from "../../Components";

const Banner = ({ banner }) => {
  if (!banner) return null;
  return (
    <Container
      href={banner.url || ''}
      backgroundImage={(banner.file && banner.file.urlPublica) || NoBanner}
    >
      <Title>{banner.title || ""}</Title>
      {banner.button_text && (
        <ArrowButton
          url={banner.url}
          target={banner.target}
          title={banner.button_text}
          position='left'
          color='#fff'
        />
      )}
    </Container>
  );
};

export default Banner;
