import React from "react";
import { Col, Row } from "reactstrap";
import { Container } from "./styles";
import { CardLibrary } from "../../Components";

const LibraryList = ({ content }) => {
  if (!content || !content.length) return null;
  return (
    <Container>
      <Row>
        {content.map((lib) => (
          <Col xs={12} lg={4} key={`cardlib-${lib.id}`}>
            <CardLibrary lib={lib} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default LibraryList;
