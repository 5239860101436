import React from "react";
import { Col, Row } from "reactstrap";
import { Container, Title } from "./styles";
import Live from "./Live";

const LivesList = ({ content }) => {
  if (!content || !content.length) return null;
  return (
    <Container>
      <Title>Entre em uma sala ao vivo</Title>
      <Row>
        {content.map((live) => (
          <Col xs='12' lg='4' key={`live-${live.id}`}>
            <Live live={live} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default LivesList;
