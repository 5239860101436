import React, { useState, useEffect } from "react";
import { Container, Icon, NavLink, arrowIcon, Dropdown } from "./styles";
import { nameFormat } from "../../../utils/manipulateString";
import { getCurrentUser } from "../../../utils";
import { getMenusById } from "../../../services/endpoints/cms/menus";
import NoProfilePic from "../../../assets/images/no-profile-picture-icon.jpg";
import DropdownLink from "./DropdownLink";
import { LinkHandler } from "../../Components";

const MENU_ID = 7;

const Menu = () => {
  const [iconUrl, setIconUrl] = useState("" || NoProfilePic);
  const [title, setTitle] = useState("");
  const [dropdownItems, setDropdownItems] = useState([]);

  const loadDropdownItems = async () => {
    try {
      const { data } = await getMenusById(MENU_ID);
      setDropdownItems(data[0].menu_items || []);
    } catch (err) {
      console.error(err);
    }
  };

  const loadUserInfo = async () => {
    try {
      const user = getCurrentUser();
      setTitle(nameFormat(user.name));
      setIconUrl(
        (user &&
          user.profile &&
          user.profile.avatar &&
          user.profile.avatar.file &&
          user.profile.avatar.file.urlPublica) ||
          NoProfilePic
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    loadUserInfo();
    loadDropdownItems();
  }, []);

  return (
    <Container>
      <Icon src={iconUrl} alt='' />

      <NavLink>
        {title && <LinkHandler title={title} />}
        <i className='fas fa-chevron-down fa' style={arrowIcon} />
      </NavLink>

      <Dropdown>
        {dropdownItems.map((dropdownItem) => (
          <DropdownLink
            link={dropdownItem}
            key={`dropdownItem-${dropdownItem.id}`}
          />
        ))}
      </Dropdown>
    </Container>
  );
};

export default Menu;
