import api from "../../../api";

export const getTestimonials = (id) => {
  return api.get(`/cms/testimonials`, {
    params: {
      content_iri: `/lms/courses/${id}`,
      publish: true,
      'order[created_at]': 'DESC'
    },
  });
};

export const createTestimonial = (body) => {
  return api.post('/cms/testimonials', body);
}