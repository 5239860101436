import React, { useState, useEffect } from "react";
import { stripTags } from "../../../utils/manipulateString";
import { Container, Content, Image, Info, Text, Button, Icon } from "./styles";
import { PageTitle } from "../../../styles/GlobalStyles";
import Placeholder from "../../../assets/images/placeholder.jpg";
import { Categories, Stars } from "../../Cards/Components";

const LibraryInternal = ({ content }) => {
  const [library, setLibrary] = useState({});

  useEffect(() => {
    if (content && content.length) {
      setLibrary(content[0]);
    }
  }, [content]);

  if (!library) return null;
  return (
    <Container>
      {library.support_material_categories && (
        <Categories
          categories={library.support_material_categories.map(
            ({ category }) => category
          )}
        />
      )}
      <PageTitle
        dangerouslySetInnerHTML={{ __html: stripTags(library.title) }}
      />
      <Content>
        <Image
          src={(library.image && library.image.urlPublica) || Placeholder}
          alt='Image da biblioteca'
        />
        <Info>
          <Text
            dangerouslySetInnerHTML={{ __html: stripTags(library.description) }}
          />
          <Stars
            content={library}
            contentIri={`/lms/support_materials/${library.id}`}
            title='Avalie este conteúdo'
          />
          {library.support_material_contents &&
            library.support_material_contents[0] &&
            library.support_material_contents[0].file && (
              <Button
                href={library.support_material_contents[0].file.urlPublica}
              >
                BAIXAR
                <Icon className='fas fa-file-download' />
              </Button>
            )}
        </Info>
      </Content>
    </Container>
  );
};

export default LibraryInternal;
