import * as yup from "yup";

export const mapValidations = (groupFields) => {
  const validations = {};

  groupFields.forEach((group) => {
    group.fields.forEach((field) => {
      if (field.validations) {
        Object.assign(validations, { ...field.validations });
      }
    });
  });

  return yup.object().shape(validations);
};
