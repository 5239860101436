import React from "react";
import { Container, Icon, Bold, Text, Span } from "./styles";
import { formatPosition } from "../../../../utils/manipulateString";

const Title = ({ title, progress, position, showContent }) => {
  return (
    <Container showContent={showContent}>
      <Icon showContent={showContent} className='fas fa-chevron-down' />
      <Bold>Aula {formatPosition(position)}</Bold>
      <Text>{title || ""}</Text>
      <Span>|</Span>
      <Bold id={`progress-lesson-${position}`}>{`${progress || 0}%`}</Bold>
    </Container>
  );
};

export default Title;
