import React, { useState } from "react";
import { Container, Form, Label, Input, TextArea, Button } from "./styles";
import { getCurrentUser } from "../../utils";
import { sendContact } from "../../services/endpoints/cms/contact";
import { PageTitle } from "../../styles/GlobalStyles";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const contact = {
        content: `<p>${message}</p> <p>&nbsp;</p>\r\n<p> Nome: <b>${name}</b>,</p>\r\n <p> E-mail: <b>${email}</b></p>`,
        subject: subject,
        user_iri: getCurrentUser().id || null,
        ip: "0.0.0.0",
        user_agent: navigator.userAgent,
      };
      await sendContact(contact);
      alert("Obrigado! Sua mensagem foi recebida!");
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    } catch (err) {
      alert("Oops! Aconteceu algum erro durante o envio da mensagem.");
      console.error(err);
    }
  };

  return (
    <Container>
      <PageTitle>Envie sua dúvida</PageTitle>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Label>
          Nome
          <Input
            placeholder='Nome'
            type='text'
            name='name'
            required
            onChange={(e) => setName(e.target.value)}
          />
        </Label>
        <Label>
          E-mail
          <Input
            placeholder='E-mail'
            type='email'
            name='email'
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </Label>
        <Label>
          Assunto
          <Input
            placeholder='Assunto'
            type='text'
            name='subject'
            required
            onChange={(e) => setSubject(e.target.value)}
          />
        </Label>
        <Label>
          Mensagem
          <TextArea
            placeholder='Mensagem'
            type='text'
            name='message'
            required
            onChange={(e) => setMessage(e.target.value)}
          />
        </Label>
        <Button type='submit'>Enviar Mensagem</Button>
      </Form>
    </Container>
  );
};

export default Contact;
