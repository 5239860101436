import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-right: 7px;
    font-size: 14px;
  }

  .ProgressBar {
    display: flex;
    border: 1px solid #fc4c02;
    border-radius: 20px;
    width: 35%;
    height: 10px;

    div {
      background: #fc4c02;
      width: ${(props) => props.progress || 0}%;
      border-radius: 20px;
    }
  }
`;
