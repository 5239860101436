import React, { useState, useEffect, useCallback } from "react";
import { getFavorites } from "../../services/endpoints/general/evaluations";
import {
  getCoursesCompleted,
  getCoursesOngoing,
} from "../../services/endpoints/lms/courses";
import { formatPosition } from "../../utils/manipulateString";
import {
  Background,
  Container,
  TitleContainer,
  Sections,
  Section,
  Bold,
  Content,
} from "./styles";
import { PageTitle } from "../../styles/GlobalStyles";
import BackgroundUrl from "../../assets/images/carbe-home-image-background.png";
import {
  Profile,
  NavbarPrimary,
  NavbarSecondary,
  Footer,
  Certificates,
  CoursesFavorited,
  CoursesWatcheds,
  CoursesOngoing,
  Spinner,
} from "../../components/Components";
import { PROFILE_STATES } from "../../utils/constants";

const PageProfile = () => {
  const [favorites, setFavorites] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [watcheds, setWatcheds] = useState([]);
  const [ongoingCourses, setOngoingCourses] = useState([]);
  const [isActive, setIsActive] = useState(PROFILE_STATES.FAVORITES);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(null);

  const handleCertificates = (certificates) => {
    const certificatesApproved = [];

    certificates.forEach((certificate) => {
      switch (true) {
        case certificate.mandatory_approval &&
          certificate.attempts &&
          certificate.max_grade >= certificate.approval_percentage:
          certificatesApproved.push(certificate);
          break;
        case !certificate.mandatory_approval:
          certificatesApproved.push(certificate);
          break;
        default:
          break;
      }
    });

    setCertificates(certificatesApproved || []);
  };

  const fetchData = useCallback(async () => {
    try {
      const favoritesResponse = await getFavorites();
      const watchedsResponse = await getCoursesCompleted();
      const ongoingCoursesResponse = await getCoursesOngoing();

      setFavorites(favoritesResponse.data || []);
      handleCertificates(watchedsResponse.data || []);
      setWatcheds(
        (watchedsResponse.data &&
          watchedsResponse.data.map(({ course }) => {
            return { ...course, ...course.course };
          })) ||
          []
      );
      setOngoingCourses(ongoingCoursesResponse.data || []);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [fetchData, reload]);

  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <Background url={BackgroundUrl}>
        <Container>
          <TitleContainer>
            <PageTitle>Perfil</PageTitle>
          </TitleContainer>
          <Profile />
          <Sections>
            <Section
              isActive={isActive === PROFILE_STATES.FAVORITES}
              onClick={(e) => setIsActive(PROFILE_STATES.FAVORITES)}
            >
              <Bold>{formatPosition(favorites.length)}</Bold>
              Favoritos
            </Section>
            <Section
              isActive={isActive === PROFILE_STATES.CERTIFICATES}
              onClick={(e) => setIsActive(PROFILE_STATES.CERTIFICATES)}
            >
              <Bold>{formatPosition(certificates.length)}</Bold>
              Certificados
            </Section>
            <Section
              isActive={isActive === PROFILE_STATES.WATCHEDS}
              onClick={(e) => setIsActive(PROFILE_STATES.WATCHEDS)}
            >
              <Bold>{formatPosition(watcheds.length)}</Bold>Assistidos
            </Section>
            <Section
              isActive={isActive === PROFILE_STATES.ONGOING_COURSES}
              onClick={(e) => setIsActive(PROFILE_STATES.ONGOING_COURSES)}
            >
              <Bold>{formatPosition(ongoingCourses.length)}</Bold>Em andamento
            </Section>
          </Sections>
          {!isLoading ? (
            <Content>
              {isActive === PROFILE_STATES.FAVORITES && (
                <CoursesFavorited
                  content={favorites}
                  hasTitle={false}
                  setReload={setReload}
                />
              )}
              {isActive === PROFILE_STATES.CERTIFICATES && (
                <Certificates content={certificates} hasTitle={false} />
              )}
              {isActive === PROFILE_STATES.WATCHEDS && (
                <CoursesWatcheds content={watcheds} hasTitle={false} />
              )}
              {isActive === PROFILE_STATES.ONGOING_COURSES && (
                <CoursesOngoing content={ongoingCourses} hasTitle={false} />
              )}
            </Content>
          ) : (
            <Spinner />
          )}
        </Container>
      </Background>
      <Footer />
    </>
  );
};

export default PageProfile;
