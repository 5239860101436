/** @format */

import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;        
    }

    html, body, #root {
        min-height: 100%;
    }


    *, button, input {
        border: 0;
        outline: 0;
        font-family: 'Source Sans Pro', sans-serif;
    }

    :root {
        --primary-color: #00205b;
        --secondary-color: #fc4c02;
    }

`;

export const GlobalContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  @media (min-width: 480px) {
    max-width: 728px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const PageContainer = styled.div`
  width: 100%;
  max-width: 100%;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PageTitle = styled.h2`
  width: 100%;
  text-align: left;
  color: #333;
  font-size: 44px;
  line-height: 44px;
  font-weight: 300;
  position: relative;
  margin: 30px 0 65px;

  &:after {
    position: absolute;
    content: "";
    width: 30px;
    height: 2px;
    padding-right: 0px;
    background-color: var(--secondary-color);
    bottom: -15px;
    left: 0;
  }
`;

export const Header = styled.header`
  width: 100%;
`;
export const Main = styled.main`
  width: 100%;
`;

export const Footer = styled.footer`
  width: 100%;
`;

export const Col = styled.div`
  margin: 0;
  padding: 0;
  width: ${({ width }) => width || "auto"};

  @media screen and (max-width: 575px) {
    width: 100% !important;
  }
`;
