import React from "react";
import Routes from "./routes";
import GlobalStyles from "./styles/GlobalStyles";
import { RefreshToken } from "./components/Components";
import Providers from "./contexts/Providers";

function App() {
  return (
    <>
      <Providers>
        <Routes />
      </Providers>
      <RefreshToken />
      <GlobalStyles />
    </>
  );
}

export default App;
