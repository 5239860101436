import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const ErrorMessageStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  text-align: center;
  flex-direction: column;

  i {
    font-size: 40px;
    color: #fc4c02;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 30px;
  }
`