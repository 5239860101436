import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 56%;
  width: 100%;
  height: 100%;
  max-height: 180px;
  position: relative;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
`;
