import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { VideoStyle } from "./styles";
import api from "../../../services/api";

const VideoFile = (props) => {
  const { url, id, updateStats } = props;

  useEffect(() => {
    api
      .post("/general/watcheds", {
        user_iri: atob(localStorage.getItem("user-endpoint")),
        progress: 0,
        content_iri: `/lms/lesson_contents/${id}`,
        watched: false,
      })
      .then((resp) => {
        updateStats();
      });

    setTimeout(() => {
      api
        .post("/general/watcheds", {
          user_iri: atob(localStorage.getItem("user-endpoint")),
          progress: 100,
          content_iri: `/lms/lesson_contents/${id}`,
          watched: true,
        })
        .then((resp) => {
          updateStats();
        });
    }, 3000);
  }, [url, id, updateStats]);

  return (
    <VideoStyle controls>
      <source id='featuredVideo' src={url} type='video/mp4' />
    </VideoStyle>
  );
};

VideoFile.propTypes = {
  url: PropTypes.string.isRequired,
};
export default VideoFile;
