import styled from "styled-components";

export const TestimonialsBox = styled.div`
  background: #f5f5f5;
  margin-bottom: 30px;
  padding: 20px 0 0;

  > h2 {
   padding: 0 15px;
   font-size: 24px;
   font-weight: normal;
   margin-bottom: 15px;
  }
`

export const TestimonialsItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  background: #e0e0e0;
  padding: 15px;
`

export const TestimonialsItemHead = styled.div`
  display: flex;
  align-items: center;

  > img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
  }

  h3 {
    margin: 0 10px;
    font-size: 16px;
  }
`

export const TestimonialsItemContent = styled.div`
  padding: 10px 0px;
  word-break: break-word;
`; 

export const NewTestimonialBox = styled.div`
  display: flex;
  padding: 15px;
  margin-bottom: ${props =>  props.mb ? "30px" : "20px"};
  
  >img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
`

export const NewTestimonialInput = styled.div`
  background: #e0e0e0;
  margin-left: 20px;
  width: 100%;
  padding: 10px 15px;
  position: relative;
  
  textarea {
    border: none;
    background: none;
    width: 100%;
    height: 80px;
  }

  &::before{
    content: '';
    position: absolute;
     width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-right: 15px solid #e0e0e0;
    top: 15px;
    left: -15px;
  }

  button {
    display: inline-block;
    padding: 4px 10px 3px;
    border-radius: 4px;
    margin: 0 5px;
    background-color: #fc4c02;
    color: #fff;
    outline: none;
  }
`