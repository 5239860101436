import React from "react";
import { Spinner as StrapSpinner } from "reactstrap";
import { Container } from "./styles";

const Spinner = ({ color, size }) => {
  return (
    <Container>
      <StrapSpinner color={color || "dark"} size={size || ""} />
    </Container>
  );
};

export default Spinner;
