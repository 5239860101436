import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  background-image: url(${({ url }) => url});
  background-position: 50% 50%;
  background-size: 1500px;
  background-repeat: no-repeat;
  background-attachment: fixed;
`;

export const BoxPhoto = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0;

  img {
    position: relative;
    width: 800px;
    max-width: 100%;
  }
`;

export const TextBox = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
  padding: 20px;
  font-size: 28px;
  line-height: 34px;
  color: #333;
  width: 250px;

  h2 {
    font-weight: bold;
    font-size: 28px;
  }

  p {
    font-size: 28px;
    font-weight: 300;
  }
`;
