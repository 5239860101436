import styled from "styled-components";

export const Container = styled.div`
  margin: 5px 0 0;
  padding: 12px 0 0;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const Icon = styled.i`
  font-size: 24px;
  color: var(--primary-color);
`;

export const Title = styled.div`
  padding: 0 0 0 6px;
  color: #333;
  font-style: normal;
  text-decoration: underline;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-color);
`;
