import React, { useEffect, useState } from "react";
import { getMenusById } from "../../../services/endpoints/cms/menus";
import { Container, Bars, Menu } from "./styles";
import MenuDropdownLink from "./MenuDropdownLink";
import MenuLink from "./MenuLink";

const primaryMenuID = 7;
const secondaryMenuID = 6;

const MenuMobile = () => {
  const [menu, setMenu] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const primaryMenuResponse = await getMenusById(primaryMenuID);
        const secondaryMenuResponse = await getMenusById(secondaryMenuID);
        setMenu(
          (prev) =>
            [
              ...prev,
              ...secondaryMenuResponse.data[0].menu_items,
              ...primaryMenuResponse.data[0].menu_items,
            ] || []
        );
      } catch (err) {
        console.error(err);
      }
    };

    if (!menu.length && isVisible) {
      loadData();
    }
  }, [menu, isVisible]);

  return (
    <Container>
      <Bars
        className='fas fa-bars'
        onClick={(e) => setIsVisible((prev) => !prev)}
      />

      <Menu isVisible={isVisible}>
        {menu.map((link) =>
          link.menu_items.length ? (
            <MenuDropdownLink dropdown={link} />
          ) : (
            <MenuLink link={link} key={`mobile-link-${link.id}`} />
          )
        )}
      </Menu>
    </Container>
  );
};

export default MenuMobile;
