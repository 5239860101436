import styled from "styled-components";
import { GlobalContainer } from "../../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  padding-top: 30px;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
`;

export const PageOptions = styled.div`
  margin: 20px 0;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
`;
