/** @format */

import React, { useEffect, useState } from "react";
import { getTrailsById } from "../../services/endpoints/lms/trails";
import { Container } from "./styles";
import { PageContainer } from "../../styles/GlobalStyles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
  Spinner,
  TrailInternal,
} from "../../components/Components";
import { useCourseStatusContext } from "../../contexts/CourseStatusProvider";
import LMSProgressProvider from "../../contexts/LMSProgressProvider";

const PageTrailInternal = ({ match }) => {
  const [id] = useState(match.params.slug || null);
  const [trail, setTrail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { courseStatusUpdated } = useCourseStatusContext();

  const fetchTrail = async () => {
    try {
      const { data } = await getTrailsById(id);
      setTrail({ ...data } || []);
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetchTrail();
    }

    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchTrail();
    }

    //eslint-disable-next-line
  }, [id, courseStatusUpdated]);

  return (
    <LMSProgressProvider>
      <NavbarPrimary />
      <NavbarSecondary />
      <PageContainer>
        <Container>
          {!isLoading ? <TrailInternal content={trail} /> : <Spinner />}
        </Container>
      </PageContainer>
      <Footer />
    </LMSProgressProvider>
  );
};

export default PageTrailInternal;
