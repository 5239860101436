import axios from "axios";
import {
  getToken,
  redirectToLogout,
  redirectToForbidden,
  redirectToPageNotFound,
} from "./auth";

const apiUrl = process.env.REACT_APP_API_URL;

const params = {
  baseURL: apiUrl,
};

const api = axios.create(params);

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = getToken();
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const status =
      error.response && error.response.status ? error.response.status : error;

    switch (status) {
      case 401:
        console.error("Token expired");
        redirectToLogout();
        break;
      case 403:
        if (getToken()) {
          redirectToForbidden();
        } else {
          redirectToLogout();
        }
        break;
      case 404:
        if (getToken()) {
          redirectToPageNotFound();
        } else {
          redirectToLogout();
        }
        break;
      case 405:
        console.error("Method Not Allowed");
        break;
      case 406:
        console.error("Not Acceptable");
        break;
      case 422:
        console.error("Unprocessable Entity");
        break;

      default:
      // redirectToLogout();
    }
    Promise.reject(error);
  }
);

export default api;
