import React, { useEffect, useState } from "react";
import {
  Container,
  Title,
  Icon,
  Courses,
  TitleQtd,
  Description,
} from "./styles";
import Course from "./Course";

const TrailCourses = ({
  trail,
  setActualCoursePosition,
  isVisible,
  setIsVisible,
}) => {
  const [trailCourses, setTrailCourses] = useState([]);
  // let indexCourse = -1;
  useEffect(() => {
    setTrailCourses((trail && trail?.trails_courses) || []);
  }, [trail]);

  const MountTitle = ({ title, qtd }) => {
    return (
      <div className="d-flex">
        {`Trilha: ${title} -`}
        <TitleQtd>
          {`${qtd > 9 ? qtd : "0" + qtd} ${qtd > 1 ? "cursos" : "curso"}`}
        </TitleQtd>
      </div>
    );
  };

  return (
    <Container>
      <Title
        className={`${isVisible ? "active" : ""}`}
        onClick={(e) => setIsVisible((prev) => !prev)}
      >
        <MountTitle
          title={trail.title}
          qtd={trailCourses.filter((item) => item.course).length}
        />

        <Icon isVisible={isVisible} className="fas fa-chevron-down" />
      </Title>

      <Description dangerouslySetInnerHTML={{ __html: trail.description }} />

      {isVisible && (
        <Courses>
          {trailCourses
            ?.filter((item) => item.course)
            ?.map(({ course }, index) => {
              // if (course) {
              //   indexCourse = indexCourse + 1;
              // }
              return (
                <>
                  {course && (
                    <Course
                      course={course}
                      coursePosition={index}
                      courses={trailCourses.filter((item) => item.course)}
                      setActualCoursePosition={setActualCoursePosition}
                      key={`trailCourse=${course.id}`}
                    />
                  )}
                </>
              );
            })}
        </Courses>
      )}
    </Container>
  );
};

export default TrailCourses;
