import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
// import { Container } from "./styles";
import { BannersSlider, BannersGroup } from "../Components";
import { getBlockByName } from "../../services/endpoints/cms/blocks";
import { getEndpoints } from "../../services/endpoints";

const BANNERS_SLIDER_NAME = "BannersSlider";
const BANNERS_GROUP_NAME = "BannersGroup";

const BannersHome = () => {
  const [bannersSlider, setBannersSlider] = useState({});
  const [bannersGroup, setBannersGroup] = useState({});

  useEffect(() => {
    const loadBanners = async () => {
      try {
        const bannerSliderEndpoint = await getBlockByName(BANNERS_SLIDER_NAME);
        const bannersGroupEndpoint = await getBlockByName(BANNERS_GROUP_NAME);

        const bannersSliderResponse = await getEndpoints(
          bannerSliderEndpoint.data[0].endpoint
        );
        const bannersGroupResponse = await getEndpoints(
          bannersGroupEndpoint.data[0].endpoint
        );

        setBannersSlider(bannersSliderResponse.data || {});
        setBannersGroup(bannersGroupResponse.data || {});
      } catch (err) {
        console.error(err);
      }
    };
    loadBanners();
  }, []);

  return (
    <Row>
      <Col xs={12} lg={8} style={{ padding: "0" }}>
        {bannersSlider && bannersSlider.banners && (
          <BannersSlider content={bannersSlider} />
        )}
      </Col>
      <Col xs={12} lg={4} style={{ padding: "0" }}>
        {bannersGroup && bannersGroup.banners && (
          <BannersGroup content={bannersGroup} />
        )}
      </Col>
    </Row>
  );
};

export default BannersHome;
