/** @format */

import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "reactstrap";
import TrailCourses from "../TrailInternal/TrailCourses";
import Progress from "../../Cards/Progress";
import { useProgressContext } from "../../../contexts/LMSProgressProvider";
import { getTrailsById } from "../../../services/endpoints/lms/trails";

const TrailInternalMeta = ({
  content,
  setActualCoursePosition,
  isVisible,
  setIsVisible,
}) => {
  const [trailCourseList, setTrailCourseList] = useState({});
  const { updatedProgress } = useProgressContext();

  useEffect(() => {
    (async () => {
      if (content && content[0] && content[0].id) {
        try {
          const { data } = await getTrailsById(content[0].id);
          setTrailCourseList(data[0] || {});
        } catch (err) {
          console.error(err);
        }
      }
    })();
  }, [content, updatedProgress]);

  return (
    <Container>
      <Row style={{ marginTop: 30 }}>
        <Col xs={12} lg={4}>
          <Progress
            progress={trailCourseList.progress || 0}
            label="Progresso total da trilha:"
          />
        </Col>
      </Row>

      <TrailCourses
        trail={trailCourseList}
        setActualCoursePosition={setActualCoursePosition}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
    </Container>
  );
};

export default TrailInternalMeta;
