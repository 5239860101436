import React from "react";
import { Container, BodyCard, EvaluationBar, Title } from "./styles";
import {
  HeaderCard,
  Stars,
  Duration,
  FavoriteButton,
  CreditBar,
  Categories,
  Progress,
} from "./Components";
import { ArrowButton, LinkHandler } from "../Components";

const CardOngoingCourse = ({ course }) => {
  if (!course) return null;

  return (
    <Container>
      <LinkHandler url={`/curso-interno/${course.slug}`}>
        <HeaderCard image={course.image ? course.image.urlPublica : ""} />
      </LinkHandler>
      <BodyCard>
        <EvaluationBar>
          <Stars content={course} contentIri={`/lms/courses/${course.id}`} />
          <Duration duration={course.duration} />
          <FavoriteButton
            courseId={course.id}
            evaluations={course.evaluations}
          />
        </EvaluationBar>

        <CreditBar
          createdBy={course.created_by}
          createdAt={course.created_at}
        />

        <Categories categories={course.categories} />

        <LinkHandler
          url={`/curso-interno/${course.slug}`}
          childrenPosition='left'
        >
          <Title>{course.title || ""}</Title>
        </LinkHandler>

        <Progress progress={course.progress}/>

        <ArrowButton
          url={`/curso-interno/${course.slug}`}
          target='_blank'
          title='SAIBA MAIS'
          position='left'
          color='#333'
        />
      </BodyCard>
    </Container>
  );
};

export default CardOngoingCourse;
