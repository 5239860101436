import styled from "styled-components";

export const Container = styled.div`
  padding: 18px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px none rgba(51, 51, 51, 0.15);
  border-bottom: 1px solid rgba(51, 51, 51, 0.15);

  @media screen and (max-width: 575px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Thumbnail = styled.div`
  padding: 43px 87px;
  max-width: 100%;
  display: inline-block;
  background-color: transparent;
  background-image: url(${({ url }) => url});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: repeat;
  cursor: pointer;
`;
export const Info = styled.div`
  padding: 0 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 575px) {
    align-items: center;
    justify-content: center;
  }
`;
export const Position = styled.span``;
export const Duration = styled.span``;
export const Title = styled.h5``;

export const WatchedSpan = styled.div`
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 700;
  font-size: 12px;
  background-color: #00985f;
  color: #fff;
`;
