import React from "react";
import { Container, Image, Info, Text, Bold, Date } from "./styles";
import AuthorIcon from "../../../assets/images/author-icon.png";

const CreditBar = ({ createdBy, createdAt }) => {
  return (
    <Container>
      <Image src={AuthorIcon} alt='icone do creditbar' />
      <Info>
        {createdBy && (
          <Text>
            <Bold>Por:</Bold>{" "}
            {createdBy && createdBy.name ? createdBy.name : ""}
          </Text>
        )}
        {createdAt && <Date>{createdAt.split(" ")[0]}</Date>}
      </Info>
    </Container>
  );
};

export default CreditBar;
