import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  margin: 40px auto 0;
`;

export const NoExam = styled.h1`
  margin: 120px auto 0;
  text-align: center;
`;
