import styled from "styled-components";

export const FooterStyled = styled.div`
  position: relative;
  bottom: 0;
  padding: 24px;
  background-color: #00205b;
  color: #fff;
  font-size: 14px;
`;

export const LogoFooter = styled.img`
  width: 130px;
`;

export const Title = styled.p`
  font-size: 16px;
`;

export const SocialFooter = styled.div`
  font-size: 30px;
  width: 80px;
  justify-content: space-around;
  display: flex;
  margin: 20px 0;

  a {
    color: #fff;
  }
`;

export const Icon = styled.i`
  padding: 7px;
  font-size: 35px;

  border-radius: 5px;
  &:hover {
    background-color: ${({ icon }) => {
      switch (icon) {
        case "instagram":
          return "#e94b5a";
        case "linkedin":
          return "#0077B5";
        default:
          return "transparent";
      }
    }};
  }
`;
