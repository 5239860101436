import styled from "styled-components";

export const Container = styled.div`
  padding: 18px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px none rgba(51, 51, 51, 0.15);
  border-bottom: 1px solid rgba(51, 51, 51, 0.15);
`;

export const Thumbnail = styled.div`
  padding: 43px 87px;
  max-width: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  background-image: url(${({ url }) => url});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: repeat;
  cursor: pointer;
  filter: grayscale(50%);
`;

export const ThumbIcon = styled.i`
  color: #fff;
  font-size: 20px;
`;

export const Info = styled.div`
  padding: 0 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
export const Position = styled.span``;
export const Duration = styled.span``;
export const CourseTitle = styled.h5``;
