import React from "react";
import * as Yup from "yup";
import GenerateForm from "../GenerateForm";
import { getFields } from "../../../services/endpoints/general/Fields/fields";
import {
  getFieldValues,
  setFieldsValues,
} from "../../../services/endpoints/general/Fields/field_values";
import { mapInitialValues } from "../GenerateForm/util/mapInitialValues";
import {
  updateCurrentUser,
  changePasswordUser,
} from "../../../services/endpoints/general/users";
import { useNotificationContext } from "../../../contexts/NotificationProvider";

const ProfileForm = ({ user, profile }) => {
  const { createNotification } = useNotificationContext();

  const FIELDS = {
    NAME: "name",
    EMAIL: "email",
    CELLPHONE: "telefone",
    ROLE: "cargo",
  };

  const groupFields = [
    {
      name: "",
      fields: [
        {
          label: "Seu nome",
          hasPlaceholder: false,
          name: FIELDS.NAME,
          value: user.name,
          required: true,
          validations: {
            name: Yup.string().required("Campo obrigatório"),
          },
        },
        {
          label: "E-mail",
          hasPlaceholder: false,
          name: FIELDS.EMAIL,
          value: user.email,
          required: true,
          validations: {
            email: Yup.string().email().required("Campo obrigatório"),
          },
        },
        {
          label: "Telefone",
          hasPlaceholder: false,
          name: FIELDS.CELLPHONE,
          value: profile.telefone ? profile.telefone.value : "",
          required: false,
          mask: "+55 (99) 99999-9999",
          maskType: "phone",
        },
        {
          label: "Cargo",
          hasPlaceholder: false,
          name: FIELDS.ROLE,
          disabled: true,
          value: profile.cargo ? profile.cargo.value : "",
          required: false,
        },
      ],
    },
  ];

  const onSubmit = async (values, actions) => {
    try {
      // tratamentos
      Object.assign(values, {
        [FIELDS.CELLPHONE]: values[FIELDS.CELLPHONE].replace(/\D/g, ""),
      });

      // init map to java project
      const fields = await getFields();
      const fieldValues = await getFieldValues();
      const fieldValuesData = {};
      fieldValues.data.forEach((field) =>
        Object.assign(fieldValuesData, {
          [field.field.title]: field.id,
        })
      );

      const myFieldsValues = mapInitialValues(groupFields);
      const dataValues = [];

      fields.data.forEach((field) => {
        if (Object.keys(myFieldsValues).includes(field.title)) {
          const obj = {
            fieldId: field.id,
            value: values[field.title],
          };
          if (fieldValuesData[field.title]) {
            Object.assign(obj, {
              id: fieldValuesData[field.title],
            });
          }
          dataValues.push(obj);
        }
      });
      // end map java project

      await updateCurrentUser({
        [FIELDS.NAME]: `${values[FIELDS.NAME]}`,
        [FIELDS.EMAIL]: `${values[FIELDS.EMAIL]}`,
      });

      // save values in fieldvalues
      await setFieldsValues(dataValues);

      // save password if exits
      if (values.plainPassword && values.plainPassword.length >= 8) {
        await changePasswordUser({
          plainPassword: values.plainPassword,
        });
      }
      actions.setSubmitting(false);

      createNotification({
        type: "success",
        message: "Dados salvos com sucesso",
        time: 5000,
      });
    } catch (e) {
      console.error("Error Profile.onSubmit", e);
      actions.setSubmitting(false);

      createNotification({
        type: "error",
        message:
          "Ocorreu um erro ao salvar os seus dados, tente novamente mais tarde",
        time: 5000,
      });
    }
  };

  return <GenerateForm groupFields={groupFields} onSubmit={onSubmit} />;
};

export default ProfileForm;
