/** @format */

import React from "react";
import PropTypes from "prop-types";
import Script from "react-load-script";
import api from "../../../services/api";
import { useProgressContext } from "../../../contexts/LMSProgressProvider";

const SambaMedia = ({
  reference,
  contentIri,
  userIri,
  courseId,
  setProgressLesson = () => {},
}) => {
  const idPlayer = "player";
  let flag = false;
  const { setUpdatedProgressParams } = useProgressContext();

  const getSettingMediaKey = async () => {
    try {
      const response = await api.get("/settings/sambatech_player_key");
      return response.data.value;
    } catch (e) {
      alert("Ocorreu um erro ao carregar os dados");
      console.error("SambaMedia.getSettingMediaKey", e);
      return "";
    }
  };

  const dispatchActionView = async (currentProgress, duration) => {
    const percent =
      currentProgress === "finished"
        ? 100
        : (currentProgress * 100) / (duration / 1000);

    try {
      api.post(`/general/watcheds`, {
        user_iri: userIri,
        progress: Math.floor(percent),
        content_iri: contentIri,
        watched: percent >= 100,
      });

      if (currentProgress === "finished") {
        setUpdatedProgressParams({ id: courseId });
        setProgressLesson(100);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const clearPlayer = () => {
    const player = window.document.getElementById(idPlayer);
    if (player) {
      player.innerHTML = "";
    }
  };

  const eventListener = (player) => {
    let eventParam = parseInt(player.eventParam, 10);

    switch (player.event) {
      case "onListen":
        if (!flag && eventParam > 1 && eventParam % 15 === 0) {
          dispatchActionView(eventParam, player.duration);
          flag = true;
        } else if (flag && eventParam > 1 && eventParam % 16 === 0) {
          flag = false;
        }
        break;
      case "onFinish":
        dispatchActionView(99, player.duration);
        setTimeout(() => {
          dispatchActionView("finished", player.duration);
        }, 1500);
        break;
      default:
        break;
    }
  };

  const init = async () => {
    const playerKey = await getSettingMediaKey();
    clearPlayer();
    new window.SambaPlayer(idPlayer, {
      ph: playerKey,
      m: reference,
      playerParams: {
        enableShare: false,
      },
      events: {
        "*": eventListener,
      },
    });
  };

  return (
    <>
      <Script
        url="https://player.sambatech.com.br/v3/samba.player.api.js"
        onLoad={init}
      />
      <div
        className="embed-responsive embed-responsive-16by9"
        style={{ height: "100%" }}
      >
        <div
          className="embed-responsive-item"
          id={idPlayer}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></div>
      </div>
    </>
  );
};

SambaMedia.propTypes = {
  reference: PropTypes.string.isRequired,
  contentIri: PropTypes.string.isRequired,
};

export default SambaMedia;
