import React, { useState } from "react";
import { Col } from "reactstrap";
import { Container } from "./styles";
import Banner from "./Banner";

const BannersGroup = ({ content }) => {
  const [banners] = useState(content.banners || []);
  return (
    <Container>
      {banners.map((banner) => (
        <Col xs={12} key={`BannerGroup-${banner.id}`}>
          <Banner banner={banner} />
        </Col>
      ))}
    </Container>
  );
};

export default BannersGroup;
