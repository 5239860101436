import styled from "styled-components";
import { GlobalContainer } from "../../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  min-height: 65vh;
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 300;
`;
