/** @format */

import React, { useEffect, useState } from "react";
import ScormApi2004 from "../../../utils/ScormApi.2004";
import { getCurrentUser } from "../../../utils/index";
import { updateCourseStatus } from "../../../services/endpoints/lms/courses";
import { ScormHelper } from "../../../utils/scorm/scorm-helper";
import { ScormApi } from "../../../utils/scorm/scorm-api";
import { useIsMounted } from "../../../hooks/use-is-mounted";
import api from "../../../services/api";
import { useProgressContext } from "../../../contexts/LMSProgressProvider";

const Scorm = ({
  content,
  contentType,
  courseId,
  contentIri,
  lessonContent = null,
  setProgressLesson = () => {},
}) => {
  const isMounted = useIsMounted();
  const { file_scorm: fileScorm } = content;
  const user_iri = `/users/${getCurrentUser().id}`;
  const { origin } = window.location;
  const path = origin.indexOf("localhost") === -1 ? origin : "/example";
  const scormFolder = process.env.REACT_APP_SCORM_FOLDER || "scorm";
  const scormName = fileScorm.name.replace(".zip", "");
  const manifestFile = "imsmanifest.xml";
  const scormFolderFile = [path, scormFolder, scormName];
  const scormUrl = [...scormFolderFile, manifestFile].join("/");
  const [urlFile, setUrlFile] = useState();
  const [initializedStats, setInitializedStats] = useState();
  const { setUpdatedProgressParams } = useProgressContext();

  let interval;
  let initialized;
  let finished;

  const updateStatus = async (progress) => {
    if (contentType === "course") {
      await updateCourseStatus({
        course: {
          id: courseId,
        },
        user_iri,
        progress,
      });
    } else if (contentType === "lesson") {
      await api.post(`/general/watcheds`, {
        user_iri,
        progress: Math.floor(progress),
        content_iri: contentIri,
        watched: progress >= 100,
      });
    }

    if (progress) {
      setUpdatedProgressParams({ id: courseId });
      setProgressLesson(100);
    } else {
      sessionStorage.setItem(
        "initScorm",
        JSON.stringify({
          id: content.id,
          init: false,
        })
      );
    }
  };

  useEffect(() => {
    const verifyStartScorm = JSON.parse(sessionStorage.getItem("initScorm"));
    if (
      !verifyStartScorm ||
      (verifyStartScorm && verifyStartScorm.id !== content.id)
    ) {
      sessionStorage.setItem(
        "initScorm",
        JSON.stringify({
          id: content.id,
          init: true,
        })
      );
    }
  }, [content.id, setUpdatedProgressParams]);

  useEffect(() => {
    const handleChange = (event, value) => {
      const statusIndex =
        value && value.version === "2004"
          ? "cmi.completion_status"
          : "cmi.core.lesson_status";
      const verifyStartScorm = JSON.parse(sessionStorage.getItem("initScorm"))
        ? JSON.parse(sessionStorage.getItem("initScorm")).init
        : true;

      if (!initializedStats) {
        if (
          verifyStartScorm &&
          !initialized &&
          value &&
          (value[statusIndex] === "incomplete" ||
            value[statusIndex] === "not attempted")
        ) {
          updateStatus(0);
          initialized = true;
        }

        if (!finished && value && value[statusIndex] === "completed") {
          updateStatus(100);
          finished = true;
        }
      }
    };

    (async () => {
      if (!isMounted.current) {
        const response = await fetch(scormUrl);
        const data = await response.text();
        const scormData = await ScormHelper.init(
          data,
          scormFolderFile
        ).readManifest();

        window.API = new ScormApi(handleChange);
        window.API_1484_11 = new ScormApi2004(handleChange);

        setUrlFile(scormData.urlFile);

        if (!!lessonContent) {
          setInitializedStats(
            lessonContent &&
              lessonContent.lesson_stats &&
              lessonContent.lesson_stats.length &&
              lessonContent.lesson_stats[0].lesson_status === "CONCLUIDO"
          );
        } else {
          setInitializedStats(
            content &&
              content.course_stats &&
              content.course_stats.length &&
              content.course_stats[0].course_status === "CONCLUIDO"
          );
        }
      }

      return () => {
        clearTimeout(interval);
      };
    })();
  }, [content, interval, isMounted, lessonContent, scormFolderFile, scormUrl]);

  if (!urlFile) return null;

  return (
    <div className="w-container embed-responsive embed-responsive-16by9 card shadow-sm">
      <iframe
        className="embed-responsive-item"
        allowFullScreen
        src={urlFile}
        scrolling="no"
        frameBorder="0"
        title={content.title}
      />
    </div>
  );
};

export default Scorm;
