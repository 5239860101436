import React, { useCallback, useEffect, useState } from "react";
import {
  getSupportMaterials,
  getSupportMaterialsTotal,
} from "../../services/endpoints/lms/supportMaterials";
import { Container, PageOptions } from "./styles";
import { PageContainer, PageTitle } from "../../styles/GlobalStyles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
  LibraryList,
  Pagination,
  Spinner,
} from "../../components/Components";
import LibraryCategories from "./LibraryCategories";
import LibrarySearch from "./LibrarySearch";
import { getCategories } from "../../services/endpoints/lms/categories";

const PageLibrary = () => {
  const DEFAULT_LIBRARY_CATEGORY_ID = 31;
  const [library, setLibrary] = useState([]);
  const [libraryTotal, setLibraryTotal] = useState(0);
  const [categories, setCategories] = useState([]);
  const [paginationDefault] = useState({
    perPage: 9,
    page: 0,
  });
  const [params, setParams] = useState({
    category_id: DEFAULT_LIBRARY_CATEGORY_ID,
    ...paginationDefault,
  });
  const [isLoading, setIsLoading] = useState(false);

  const fetchLibrary = useCallback(async () => {
    try {
      const { data } = await getSupportMaterials(params);
      setLibrary(data || []);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [params]);

  const fetchLibraryTotal = useCallback(async () => {
    try {
      const { data } = await getSupportMaterialsTotal(params);
      setLibraryTotal(data || 0);
    } catch (err) {
      console.error(err);
    }
  }, [params]);

  const fetchCategories = useCallback(async () => {
    try {
      const { data } = await getCategories({
        "wherein[parent.slug]": "'biblioteca'",
      });
      setCategories(data || []);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchLibrary();
    fetchLibraryTotal();
    fetchCategories();
  }, [params, fetchLibrary, fetchLibraryTotal, fetchCategories]);

  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <PageContainer>
        <Container>
          <PageTitle>Biblioteca</PageTitle>
          <PageOptions>
            <LibrarySearch
              setParams={setParams}
              paginationDefault={paginationDefault}
              isLoading={isLoading}
            />
            <LibraryCategories
              categories={categories}
              setParams={setParams}
              paginationDefault={paginationDefault}
              defaultLibraryCategoryId={DEFAULT_LIBRARY_CATEGORY_ID}
              isLoading={isLoading}
            />
          </PageOptions>

          {!isLoading ? <LibraryList content={library} /> : <Spinner />}

          <Pagination
            setParams={setParams}
            paginationDefault={paginationDefault}
            totalItems={libraryTotal}
          />
        </Container>
      </PageContainer>
      <Footer />
    </>
  );
};

export default PageLibrary;
