import React, { useCallback, useEffect, useState } from "react";
import { getLivesById } from "../../services/endpoints/general/lives";
import { Container } from "./styles";
import { PageContainer } from "../../styles/GlobalStyles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
  Spinner,
  LiveInternal,
} from "../../components/Components";

const PageLiveInternal = ({ match }) => {
  const [id] = useState(match.params.slug);
  const [live, setLive] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchLive = useCallback(async () => {
    try {
      const { data } = await getLivesById(id);
      setLive(data || []);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [id]);

  useEffect(() => {
    setIsLoading(true);
    fetchLive();
  }, [fetchLive]);

  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <PageContainer>
        <Container>
          {!isLoading ? <LiveInternal content={live} /> : <Spinner />}
        </Container>
      </PageContainer>
      <Footer />
    </>
  );
};

export default PageLiveInternal;
