import styled from "styled-components";

export const Container = styled.div`
  margin: 0 25px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 575px) {
    margin: 20px 0;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 180px;
  cursor: pointer;
`;

export const Icon = styled.i`
  font-size: 14px;
  margin: 0 10px;
`;

export const Dropdown = styled.div`
  z-index: 2;
  width: 100%;
  position: absolute;
  top: 150%;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.32);
`;

export const DropdownLink = styled.div`
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: #222222;
  cursor: pointer;

  &:hover {
    background-color: #eaeaea;
  }
`;
