import styled from "styled-components";

export const Container = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const Title = styled.p`
  padding: 0 10px 0 0;
`;
export const Icons = styled.div``;
export const Icon = styled.i`
  font-size: ${({ starSize }) => starSize || "16px"};
  color: var(--primary-color);
  cursor: ${({ hasEditOption }) => (hasEditOption ? "pointer" : "default")};
`;
