import React, { useState, useEffect, useCallback } from "react";
import { getCoursesByKeyword } from "../../services/endpoints/lms/courses";
import { Container, CourseNotFound, Title, Icon } from "./styles";
import {
  NavbarPrimary,
  NavbarSecondary,
  Footer,
  CoursesList,
  Spinner,
} from "../../components/Components";

const PageSearch = ({ match }) => {
  const [keyword] = useState(match.params.keyword);
  const [courses, setCourses] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCourses = useCallback(async () => {
    try {
      const { data } = await getCoursesByKeyword(keyword);
      setCourses(data || []);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [keyword]);

  useEffect(() => {
    setIsLoading(true);
    fetchCourses();
  }, [fetchCourses]);

  return (
    <>
      <NavbarPrimary />
      <NavbarSecondary />
      <Container>
        {!isLoading ? (
          courses &&
          (courses.length ? (
            <CoursesList content={courses} hasTitle={false} />
          ) : (
            <CourseNotFound>
              <Title>Nenhum curso encontrado.</Title>
              <Icon className='far fa-frown-open' />
            </CourseNotFound>
          ))
        ) : (
          <Spinner />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default PageSearch;
