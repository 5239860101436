import api from "../../../api";

export const getLives = (params = {}) => {
  return api.get(`/general/lives`, {
    params: {
      ...params,
    },
  });
};

export const getLivesTotal = (params = {}) => {
  return api.get(`/general/lives/count_by`, {
    params: {
      ...params,
    },
  });
};

export const getLivesById = (id, params = {}) => {
  return api.get(`/general/lives`, {
    params: {
      id,
      ...params,
    },
  });
};
