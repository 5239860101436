import React, { useEffect, useState } from "react";
import { getWatched } from "../../../../../../services/endpoints/general/watcheds";
import { formatPosition } from "../../../../../../utils/manipulateString";
import PlaceHolder from "../../../../../../assets/images/placeholder.jpg";
import {
  Container,
  Thumbnail,
  Info,
  Position,
  Duration,
  Title,
  WatchedSpan,
} from "./styles";

const NextVideo = ({ nextVideo, position, totalVideos, setCurrentVideo }) => {
  const [isLessonWatched, setIsLessonWatched] = useState(false);

  const fetchIsLessonWatched = async (lessonContent) => {
    try {
      const { data } = await getWatched(
        `/lms/lesson_contents/${lessonContent.id}`
      );
      setIsLessonWatched(data && data[0] && data[0].watched);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchIsLessonWatched(nextVideo);
  }, [nextVideo]);

  if (!nextVideo) return null;
  return (
    <Container onClick={(e) => setCurrentVideo(position)}>
      <Thumbnail
        url={
          (nextVideo.thumbnail && nextVideo.thumbnail.urlPublica) || PlaceHolder
        }
      />
      <Info>
        <Position>
          Vídeo {formatPosition(position + 1)} de {totalVideos}
        </Position>
        <Duration>{nextVideo.duration || "00:00"}</Duration>
        <Title>{nextVideo.title || ""}</Title>
        {isLessonWatched && <WatchedSpan>VISTO</WatchedSpan>}
      </Info>
    </Container>
  );
};

export default NextVideo;
