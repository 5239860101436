import styled from "styled-components";
import { GlobalContainer, PageTitle } from "../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(PageTitle)`
  width: 100%;
  text-align: left;
`;

export const Image = styled.img`
  width: 100%;
`;

export const Description = styled.p`
  margin: 40px 0 10px;
  padding: 0 0 20px;
  font-size: 17px;
  line-height: 28px;

  @media screen and (max-width: 575px) {
    text-align: center;
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 528px;

  @media screen and (max-width: 575px) {
    height: 400px;
  }
`;
