import React from "react";
import { PdfContainer } from "./styles";
// import { updateWatcheds } from "../../../services/endpoints/general/watcheds";
// import { useCourseStatusContext } from "../../../contexts/CourseStatusProvider";

const FilePDF = ({ content, contentIri, userIri }) => {
  // const { setCourseStatusUpdated } = useCourseStatusContext();

  // const handleClick = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const url = content.file.urlPublica;
  //     if (window.open(url, "_blank")) {
  //       const body = {
  //         user_iri: userIri,
  //         progress: 0,
  //         content_iri: contentIri,
  //         watched: false,
  //       };
  //       await updateWatcheds(body);

  //       Object.assign(body, { progress: 100, watched: true });
  //       await updateWatcheds(body);
  //       setCourseStatusUpdated(new Date().toLocaleString());
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  return (
    <div className='embed-responsive embed-responsive-16by9'>
      <PdfContainer className='embed-responsive-item'>
        {/* <PdfIcon className='fas fa-file-download' />
        <PdfText>
          <strong onClick={(e) => handleClick(e)}>Clique aqui</strong>
          {" realizar download"}
        </PdfText> */}
        <div className='mobile-pdf'>
          <a
            href={content.file.urlPublica}
            target='_blank'
            rel='noopener noreferrer'
          >
            Abrir
          </a>
        </div>
        <iframe
          className='pdf-frame'
          title='featuredPDFView'
          id='featuredPDFView'
          src={`${content.file.urlPublica}#toolbar=0&navpanes=0&scrollbar=0`}
        />
      </PdfContainer>
    </div>
  );
};

export default FilePDF;
