/** @format */

import React, { useState, useEffect } from "react";
import {
  Container,
  Header,
  Content,
  LeftContainer,
  RightContainer,
  Video,
} from "./styles";
import { ContentMedia, ExamCongratsMessage } from "../../Components";
import Title from "./Title";
import Sections from "./Sections";
import Notes from "./Notes";
import { useCourseStatusContext } from "../../../contexts/CourseStatusProvider";
import { getLessonStatus } from "../../../services/endpoints/lms/lessons";

const Lesson = ({
  lesson,
  lessonPosition,
  currentOpenLesson,
  setCurrentOpenLesson,
  courseId,
}) => {
  const [showContent, setShowContent] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(0);
  const [lessonStatus, setLessonStatus] = useState({});
  const [lessonExam, setLessonExam] = useState();
  const [progressLesson, setProgressLesson] = useState(0);
  const [isLessonContentExpanded, setIsLessonContentExpanded] = useState(false);
  const { courseStatusUpdated } = useCourseStatusContext();

  useEffect(() => {
    setLessonStatus(
      (lesson && lesson.lesson_stats && lesson.lesson_stats[0]) || {}
    );
  }, [lesson]);

  useEffect(() => {
    setShowContent(currentOpenLesson === lessonPosition);
  }, [lessonPosition, currentOpenLesson]);

  useEffect(() => {
    const fetchLessonStatus = async () => {
      try {
        const { data } = await getLessonStatus(lesson.id);
        if (data[0]) {
          setLessonStatus(data[0]);
          setProgressLesson(data[0].progress);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchLessonStatus();

    //eslint-disable-next-line
  }, [courseStatusUpdated]);

  useEffect(() => {
    setLessonExam(
      (lesson &&
        lesson.lesson_exams &&
        lesson.lesson_exams[0] &&
        lesson.lesson_exams[0].exam) ||
        null
    );
    //eslint-disable-next-line
  }, [lessonStatus]);

  const handleShowContentClick = (e) => {
    e.preventDefault();
    setCurrentOpenLesson(
      currentOpenLesson === lessonPosition ? null : lessonPosition
    );
  };

  if (!lesson) return null;
  return (
    <Container>
      <Header onClick={(e) => handleShowContentClick(e)}>
        <Title
          title={lesson.title}
          progress={progressLesson}
          position={lessonPosition + 1}
          showContent={showContent}
        />
      </Header>
      {showContent && (
        <Content showContent={showContent}>
          <LeftContainer isExpanded={isLessonContentExpanded}>
            {showContent && lesson.lesson_contents[currentVideo] && (
              <Video isExpanded={isLessonContentExpanded}>
                <ContentMedia
                  id="content-media"
                  content={lesson.lesson_contents[currentVideo]}
                  position={lessonPosition + 1}
                  courseId={courseId}
                  setLessonStatus={setLessonStatus}
                  lessonContent={lesson}
                  setProgressLesson={setProgressLesson}
                />
              </Video>
            )}
            <Sections
              lesson={lesson}
              currentVideo={currentVideo}
              setCurrentVideo={setCurrentVideo}
              isLessonContentExpanded={isLessonContentExpanded}
              setIsLessonContentExpanded={setIsLessonContentExpanded}
            />
          </LeftContainer>
          <RightContainer isExpanded={isLessonContentExpanded}>
            <Notes lessonId={lesson.id} />
          </RightContainer>

          {lessonStatus?.lesson_status === "CONCLUIDO" && lessonExam && (
            <ExamCongratsMessage
              exam={lessonExam}
              parentId={lesson.id}
              parentType="lesson"
              parentStatus={lessonStatus}
              redirectUrl={window.location.pathname}
            />
          )}
        </Content>
      )}
    </Container>
  );
};

export default Lesson;
